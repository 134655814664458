import React, { FC, ReactNode } from 'react';
import { Col, Image, Row } from 'antd';
import authBg from '../assets/authBg.png';
import logo from '../assets/logo.svg';
import { renderRoutes, RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import { Outlet, useNavigate } from 'react-router-dom';
import PAGES_PATHS from '../utils/constants/pages-path.constants';

interface AuthLayoutProps {
  // children: ReactNode
  // route: RouteConfig
}

const SplashLayout: FC<any> = () => {
  const naviate = useNavigate();

  const handleLogoClick = () => {
    naviate('/' + PAGES_PATHS.DASHBOARD);
  }
  return (
    <Row style={{ height: '100vh' }}>
      <Col xs={0} sm={0} md={8} className="h-100 position-relative">
        <Image src={authBg} width="100%" height="100%" preview={false}></Image>
        <div className="position-absolute top-50 start-50 translate-middle-x" onClick={handleLogoClick}>
          <Image src={logo} height="100%" preview={false}></Image>
        </div>
      </Col>
      <Col xs={24} sm={24} md={16} className="h-100 overflow-y-auto">
        <Outlet />
        {/* <div className="d-flex justify-content-center align-items-center h-100">
          <div
            className="bg-white"
            style={{
              boxShadow: "0px 15px 32px rgba(0, 0, 0, 0.1)",
              borderRadius: 32,
              padding: "84px 82px",
            }}
          >
           
          </div>
        </div> */}
      </Col>
    </Row>
  );
};

export default SplashLayout;
