import { FC, ReactNode } from "react";


interface BoxCard {
    children: ReactNode;
}

const BoxCard: FC<BoxCard> = ({ children }) => {
    return (<div
        className="bg-white"
        style={{
            boxShadow: '0px 15px 32px rgba(0, 0, 0, 0.1)',
            borderRadius: 32,
            padding: '42px 41px',
        }}
    >
        {
            children
        }
    </div>)
}

export default BoxCard;