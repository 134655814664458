import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

export interface ListSellersPayload {
  currentPage?: number;
  pageSize?: number;
  as?: 'csv';
}

function listSellers(args: ListSellersPayload) {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.GET_SELLER_LIST;
  if (args.currentPage && args.pageSize) {
    return axios.get(url, {
      params: {
        page: args.currentPage,
        pageSize: args.pageSize,
      },
    });
  }
  return axios.get(url, {
    params: {
      as: args.as,
    },
  });
}

const deleteSellers = (payload: { ids: number[] }) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.DELETE_SELLERS;
  return axios.post(url, payload);
};

export { listSellers, deleteSellers };
