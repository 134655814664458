import { Button, Col, Form, Input, Row, Select, Space, Typography, Upload } from "antd";
import { UploadOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { FC, Fragment } from "react";
import {BusinessRole, constantsSelector, UserRole} from "../../../../store/constants/constantsSlice";
import Country from "../../../../utils/countryList";
import {useAppSelector} from "../../../../store/hooks";



interface RegistrationFormInterface {
    onFinish?: (values: any) => void;
    onFinishFailed?: (errorInfo: any) => void;
    initialValue: {
        phone: {
            dialCode: string | number,
            number: number | string,
        }
        businessRole: number
    },
    submitting: boolean
}

const columnFlex = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    sl: 12,
};

export interface FormDataType {
    businessName: string;
    businessRole: number;
    photos?: File[]
    phoneNumber: string;
    email: string;
    storeAddress: string;
    zipCode: string;
    country: string;
    state: string;
    city: string;
    latitude: string;
    longitude: string;
    referralCode?: string;
    users: {
        firstName: string,
        lastName: string,
        phoneNumber: string,
        dinNo?: string,
        email: string,
        photo?: File
    }[],
    gstNumber?: string;
    panNumber?: string;
    legalDocuments?: {
        type: string;
        file: File
    }[]
}

const RegistrationForm: FC<RegistrationFormInterface> = ({ onFinish, onFinishFailed, initialValue, submitting }) => {
    const [form] = Form.useForm<FormDataType>();

    const businessRoles = useAppSelector(constantsSelector.listBusinessRoles);

    const longitudeValidator = (rule: any, value: any) => {
        if (!value) {
            return Promise.reject('Longitude is required');
        }

        if (value < -180) {
            return Promise.reject('Longitude must be greater than -180');
        }

        if (value > 180) {
            return Promise.reject('Longitude must be less than 180');
        }
        return Promise.resolve();
    };

    const latitudeValidator = (rule: any, value: any) => {
        if (!value) {
            return Promise.reject('Latitude is required');
        }

        if (value < -90) {
            return Promise.reject('Latitude must be greater than -90');
        }

        if (value > 90) {
            return Promise.reject('Latitude must be less than 90');
        }

        return Promise.resolve();
    };

    const dinNoValidator = (rule: any, value: any) => {
        if (value && value.length !== 8) {
            return Promise.reject('DIN number must be 8 digit number');
        }
        return Promise.resolve();
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size="large"
                initialValues={{
                    country: 'IN',
                    users: [
                        {
                            firstName: "",
                            lastName: "",
                            phoneNumber: "",
                            dinNo: "",
                            email: "",
                            photo: undefined,
                        },
                    ],
                }}
            >
                <Row gutter={[10, 0]} className="text-start">
                    <Col span={24}>
                        <Form.Item
                            label="Business Full Name"
                            name="businessName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter business name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label="Business Type"
                            name="businessRole"
                            initialValue={initialValue.businessRole}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Business Type!',
                                },
                            ]}
                        >
                            <Select allowClear disabled>
                                {businessRoles.map((o) => (
                                    <Select.Option key={o.id} value={o.id}>
                                        {o.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label='Store photos'
                            name='photos'
                            getValueFromEvent={({ file, fileList }) => {
                                return fileList.map((file: any) => file.originFileObj);
                            }}
                        >
                            <Upload
                                beforeUpload={() => false}
                                listType='picture-card'
                            >
                                <Button icon={<PlusOutlined />} shape='circle' />
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col {...columnFlex}>
                        <Form.Item
                            label="Phone Number"
                            name="phoneNumber"
                            initialValue={`${initialValue.phone.dialCode}${initialValue.phone.number}`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your phone number!',
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col {...columnFlex}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter valid email',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {/*
                    <Col span={24}>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input password',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col> */}

                    <Col span={24}>
                        <Form.Item
                            label="Store Address"
                            name="storeAddress"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter store address',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col {...columnFlex}>
                        <Form.Item
                            label="ZipCode"
                            name="zipCode"
                            rules={[{ required: true, message: 'Please enter zip code' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col {...columnFlex}>
                        <Form.Item
                            label="Country"
                            name="country"
                            rules={[{ required: true, message: 'Please select country' }]}
                        >
                            <Select
                                disabled
                                style={{ width: '100%' }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={Country.getList().map(country => ({
                                    value: country.code,
                                    label: country.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col {...columnFlex}>
                        <Form.Item
                            label="State"
                            name="state"
                            rules={[{ required: true, message: 'Please enter state' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col {...columnFlex}>
                        <Form.Item
                            label="City"
                            name="city"
                            rules={[{ required: true, message: 'Please enter city' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col {...columnFlex}>
                        <Form.Item
                            label="Latitude"
                            name="latitude"
                            rules={[
                                { validator: latitudeValidator }
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>

                    <Col {...columnFlex}>
                        <Form.Item
                            label="Longitude"
                            name="longitude"
                            rules={[
                                { validator: longitudeValidator }
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label="Select Location"
                            name="location"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label="Referral Code"
                            name="refferalCode">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{ marginTop: "1rem" }}>
                        <Typography.Title level={3}>
                            Owner / Partner Details
                        </Typography.Title>
                    </Col>

                    <Form.List
                        name='users'
                    >
                        {
                            (fields, { add, remove }) => (
                                <>
                                    {
                                        fields.map(({ key, name, ...restField }, index) => (
                                            <Fragment key={index}>
                                                <Col span={24}>
                                                    <div className='row align-items-center gx-2'>
                                                        <div className='col-auto'>
                                                            <Typography.Title level={4} className="mb-0" >Member {index + 1}</Typography.Title>
                                                        </div>
                                                        <div className='col-auto'>
                                                            {fields.length > 1 ? (
                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(name)}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col {...columnFlex}
                                                >
                                                    <Form.Item
                                                        label="First Name"
                                                        name={[name, "firstName"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter first name',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>

                                                <Col {...columnFlex}
                                                >
                                                    <Form.Item
                                                        label="Last Name"
                                                        name={[name, "lastName"]}
                                                        rules={[{ required: true, message: 'Please enter last name' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col {...columnFlex}
                                                >
                                                    <Form.Item
                                                        label="Phone Number"
                                                        name={[name, "phoneNumber"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter phone number',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col {...columnFlex}
                                                >
                                                    <Form.Item
                                                        label="Email"
                                                        name={[name, "email"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter email',
                                                            },
                                                            {
                                                                type: 'email',
                                                                message: 'Please enter valid email',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}
                                                >
                                                    <Form.Item
                                                        label="DIN Number"
                                                        name={[name, "dinNo"]}
                                                        rules={[
                                                            { validator: dinNoValidator }
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[name, "photo"]}
                                                        getValueFromEvent={(e) => {
                                                            // if (Array.isArray(e)) {
                                                            //   return e;
                                                            // }
                                                            return e && e.file;
                                                        }}
                                                        label="Upload Photo"
                                                        valuePropName="photo"
                                                    >
                                                        <Upload multiple={false} beforeUpload={() => false}>
                                                            <Button icon={<UploadOutlined />}>Select File</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                                {/* </Row> */}
                                            </Fragment>))
                                    }
                                    <Col span={24}>
                                        <div className='d-flex justify-content-center'>
                                            <div>
                                                <Button icon={<PlusOutlined />} shape='circle' onClick={() => add()} />
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            )
                        }
                    </Form.List>

                    <Col span={24} style={{ marginTop: "1rem" }}>
                        <Typography.Title level={3}>
                            Business Registration Details
                        </Typography.Title>
                    </Col>

                    <Col {...columnFlex}>
                        <Form.Item
                            label="GST Number"
                            name="gstNumber"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col {...columnFlex}>
                        <Form.Item
                            label="PAN Number"
                            name="panNumber"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Form.List
                        name='legalDocuments'
                    >
                        {
                            (fields, { add, remove }) => (
                                <>
                                    {
                                        fields.map(({ key, name, ...restField }, index) => (
                                            <Fragment key={index}>
                                                <Col span={24}
                                                >
                                                    <Form.Item
                                                        label="Document"
                                                    >
                                                        <Space className="d-flex align-items-baseline" size={[10, 10]}>
                                                            <Form.Item
                                                                name={[name, "type"]}
                                                                rules={[{ required: true, message: 'Please enter label' }]}
                                                                className='mb-0'
                                                            >
                                                                <Input
                                                                    placeholder="Label"
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[name, "file"]}
                                                                getValueFromEvent={(e) => {
                                                                    return e && e.file;
                                                                }}
                                                                valuePropName="file"
                                                                className='mb-0'
                                                            >
                                                                <Upload multiple={false} beforeUpload={() => false}>
                                                                    <Button icon={<UploadOutlined />}>Upload Document</Button>
                                                                </Upload>
                                                            </Form.Item>
                                                            <div className="align-self-center">
                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(name)}
                                                                />
                                                            </div>
                                                        </Space>
                                                    </Form.Item>
                                                </Col>
                                            </Fragment>))
                                    }
                                    <Col span={24} className="mb-3">
                                        <div className='d-flex justify-content-center'>
                                            <div>
                                                <Button icon={<PlusOutlined />} shape='circle' onClick={() => add()} />
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            )
                        }
                    </Form.List>

                    <Col span={12}>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            loading={submitting}
                            disabled={submitting}
                        >
                            Sign Up
                        </Button>
                    </Col>

                    <Col span={12}>
                        <Button
                            block
                            type="default"
                            htmlType="reset"
                            loading={submitting}
                            disabled={submitting}
                        >
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default RegistrationForm;