import React, { FC, useState } from 'react';
import { Button, Col, message, Row, Space, Typography } from 'antd';
import OtpInput from 'react-otp-input';
import { OtpWrapper } from './style';
import { AxiosResponse } from 'axios';
import verifyOtp from '../../../services/auth/verify-otp';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import PAGES_PATHS from '../../../utils/constants/pages-path.constants';
import sendOtp from '../../../services/auth/send-otp';

interface EnterOtpFormProps { }

const duration = 30;

const EnterOtpForm: FC<EnterOtpFormProps> = () => {
  const [otp, setOtp] = useState('');
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitText, setSubmitText] = useState('Verify and Continue');
  const [resendButtonText, setResendButtonText] = useState('Resend');

  const time = new Date();
  time.setSeconds(time.getSeconds() + duration);

  const { seconds } = useTimer({ expiryTimestamp: time });

  const navigate = useNavigate();

  const handleVerifyOtp = async () => {
    try {
      setSubmitText('Verifying');
      setLoading(true);
      let res: AxiosResponse = await verifyOtp({
        body: {
          otp: otp,
          phoneNumber: `${state.dialCode + state.phoneNumber}`,
        },
      });
      setSubmitText('Verified');
      setLoading(false);
      message.success('otp is verified');
      navigate('/' + PAGES_PATHS.REGISTER, { state: state });
    } catch (error: any) {
      setSubmitText('Verify and Continue');
      setLoading(false);
      if (error?.response) {
        message.error(error.response.data.message);
      }
      console.log(error);
    }
  };

  const handleResendOtp = async () => {
    try {
      setResendButtonText('Resending');
      let res: AxiosResponse = await sendOtp({
        body: {
          phoneNumber: `${state?.dialCode}${state?.phoneNumber}`,
        },
      });
      setResendButtonText('Resend');
    } catch (error: any) {
      setResendButtonText('Resend');
      console.log(error);
    }
  };

  return (
    <Row className="h-100 align-items-center" justify="center">
      <Col xs={{ span: 22 }} sm={{ span: 20 }} md={{ span: 18 }}>
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <Row
            className="bg-white"
            style={{
              boxShadow: '0px 15px 32px rgba(0, 0, 0, 0.1)',
              borderRadius: 32,
              padding: '42px 41px',
            }}
            gutter={[0, 16]}
            justify="center"
          >
            <Col span={24}>
              <div className="text-center">
                <Typography.Title level={2} style={{ margin: 0 }}>
                  Signup
                </Typography.Title>
              </div>
            </Col>
            <Col span={24}>
              <div className="text-center">
                <Typography>{`Enter OTP sent to the number ${state?.dialCode}${state?.phoneNumber}`}</Typography>
              </div>
            </Col>
            <Col span={24} className="mt-3 text-start">
              {/* <Form
                onValuesChange={(values) => {
                  console.log(values);
                }}
              >
                <FormItem> */}
              <OtpWrapper>
                <OtpInput
                  inputStyle={{
                    background: '#FFF',
                    border: '1px solid #E5E5E5',
                    borderRadius: 9,
                    width: '30px',
                    height: '30px',
                    padding: '5px',
                    marginRight: '1rem',
                  }}
                  value={otp}
                  onChange={(otp: any) => setOtp(otp)}
                  numInputs={6}
                />
              </OtpWrapper>
              {/* </FormItem>
              </Form> */}
            </Col>
            <Col span={16}>
              <Button type="primary" block loading={loading} onClick={handleVerifyOtp}>
                {submitText}
              </Button>
            </Col>
            <Col span={24}>
              <Typography className="mt-3 text-center">
                {`Haven’t received OTP? `}
                {seconds === 0 ? (
                  <Button
                    type="link"
                    style={{ fontSize: 'inherit', padding: '0' }}
                    onClick={handleResendOtp}
                  >
                    {resendButtonText}
                  </Button>
                ) : (
                  `Resend in (${seconds}s)`
                )}
              </Typography>
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default EnterOtpForm;
