import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

interface ArgsInterface {
  body: Record<string, any>;
}

const login = (args: ArgsInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.LOGIN;
  return axios.post(url, args.body);
};

export default login;
