import { createSlice } from '@reduxjs/toolkit';
import {
  getDeliveryTypes,
  getStoreTypes,
  // getUserRoles,
  listBusinessRolesAsync,
} from './constantsActions';
import { RootState } from '../store';

export interface GeneralTypeInterface {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface UserRole extends GeneralTypeInterface {
  group: 'seller' | 'user' | 'admin';
}

export type BusinessRole = {
  id: number;
  name: string;
  group: string;
};

interface ConstantState {
  deliveryTypes: GeneralTypeInterface[];
  // storeTypes: GeneralTypeInterface[];
  businessRoles: BusinessRole[];
  userRoles: UserRole[];
  loading: {
    getUserRoles: boolean;
    // getStoreTypes: boolean;
    listBusinessRoles: boolean;
    getDeliveryTypes: boolean;
  };
  error: {
    getUserRoles: any;
    // getStoreTypes: any;
    listBusinessRoles: any;
    getDeliveryTypes: any;
  };
}

const initialState: ConstantState = {
  deliveryTypes: [],
  // storeTypes: [],
  userRoles: [],
  businessRoles: [],
  loading: {
    getUserRoles: false,
    getDeliveryTypes: false,
    listBusinessRoles: false,
  },
  error: {
    getUserRoles: null,
    getDeliveryTypes: null,
    listBusinessRoles: null,
  },
};

const ConstantSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(getUserRoles.pending, (state) => {
      //   state.loading.getUserRoles = true;
      // })
      // .addCase(getUserRoles.fulfilled, (state, action) => {
      //   state.loading.getUserRoles = false;
      //   state.userRoles = action.payload.types;
      // })
      // .addCase(getUserRoles.rejected, (state, action) => {
      //   state.loading.getUserRoles = false;
      //   state.error.getUserRoles = action.payload;
      // })
      // .addCase(getStoreTypes.pending, (state) => {
      //   state.loading.getStoreTypes = true;
      // })
      // .addCase(getStoreTypes.fulfilled, (state, action) => {
      //   state.loading.getStoreTypes = false;
      //   state.storeTypes = action.payload.types;
      // })
      // .addCase(getStoreTypes.rejected, (state, action) => {
      //   state.loading.getStoreTypes = false;
      //   state.error.getStoreTypes = action.payload;
      // })
      .addCase(getStoreTypes.pending, (state) => {
        state.loading.listBusinessRoles = true;
      })
      .addCase(listBusinessRolesAsync.fulfilled, (state, action) => {
        state.loading.listBusinessRoles = false;
        state.businessRoles = action.payload.data;
      })
      .addCase(listBusinessRolesAsync.rejected, (state, action) => {
        state.loading.listBusinessRoles = false;
        state.error.listBusinessRoles = action.payload;
      })
      .addCase(getDeliveryTypes.pending, (state) => {
        state.loading.getDeliveryTypes = true;
      })
      .addCase(getDeliveryTypes.fulfilled, (state, action) => {
        state.loading.getDeliveryTypes = false;
        state.deliveryTypes = action.payload.types;
      })
      .addCase(getDeliveryTypes.rejected, (state, action) => {
        state.loading.getDeliveryTypes = false;
        state.error.getDeliveryTypes = action.payload;
      });
  },
});

export const constantsSelector = {
  getUserRoles: (state: RootState) => state.constants.userRoles,
  getDeliveryTypes: (state: RootState) => state.constants.deliveryTypes,
  // getStoreTypes: (state: RootState) => state.constants.storeTypes,
  listBusinessRoles: (state: RootState) => state.constants.businessRoles,
  getBusinessRoles: (state: RootState) => state.constants.businessRoles,
  getSpecificUserRoles: (type: UserRole['group']) => (state: RootState) =>
    state.constants.userRoles.filter((u) => u.group === type),
};

export default ConstantSlice.reducer;
