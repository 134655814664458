import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC, Key, useEffect, useState } from "react"
import { ServiceRequest } from "../../../../services/garage/list-service-requests.service";
import { useModel } from "../model";


interface ServiceRequestsTable {
    list: ServiceRequest[]
}

type TableRecord = {
    key: Key;
} & ServiceRequest
const ServiceRequestsTable: FC<ServiceRequestsTable> = ({ list }) => {
    const [dataSource, setDataSource] = useState<TableRecord[]>([]);
    const columns: ColumnsType<TableRecord> = [
        {
            title: 'Vehicle Type',
            dataIndex: 'vehicleType',
            render: (cellData) => (cellData ? cellData : '-')
        },
        {
            title: 'Make',
            dataIndex: 'make',
            render: (cellData) => (cellData ? cellData : '-')
        },
        {
            title: 'Model',
            dataIndex: 'model',
            render: (cellData) => (cellData ? cellData : '-')
        },
        {
            title: 'Variant',
            dataIndex: 'variant',
            render: (cellData) => (cellData ? cellData : '-')
        },
        {
            title: 'Gear',
            dataIndex: 'gear',
            render: (cellData) => (cellData ? cellData : '-')
        },
        {
            title: 'Year',
            dataIndex: 'year',
            render: (cellData) => (cellData ? cellData : '-')
        },
        {
            title: 'Color',
            dataIndex: 'color',
            render: (cellData) => (cellData ? cellData : '-')
        },
        {
            title: 'Size',
            dataIndex: 'size',
            render: (cellData) => (cellData ? cellData : '-')
        },
        {
            title: 'Service',
            dataIndex: 'serviceType',
            render: (cellData) => (cellData ? cellData : '-')
        },
        {
            title: 'Sub Service',
            dataIndex: 'subServiceType',
            render: (cellData) => (cellData ? cellData : '-')
        }
    ];

    const { setSelectedRecordID } = useModel();



    const createRows = (list: ServiceRequest[]) => {
        return list.map(request => ({
            key: request.id,
            ...request
        }));
    }

    const handleOnSelect = (selectedRowKeys: React.Key[], selectedRows: TableRecord[]) => {
        setSelectedRecordID?.(selectedRowKeys);
    }

    const rowSelection: {
        type: 'checkbox',
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableRecord[]) => void
    } = {
        type: 'checkbox',
        onChange: handleOnSelect,
    };

    useEffect(() => {
        let requests = createRows(list);
        setDataSource(requests);
    }, [list]);



    return <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowSelection={{
            ...rowSelection
        }}
    />
}

export default ServiceRequestsTable;