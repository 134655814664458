import React, { FC } from "react";
import { Button, Col, Form, Input, message, Row, Space } from "antd";
import { createServiceRequestAsync } from "../../../../store/garage/serviceRequests.actions";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks";
// import { FormDataType } from "../../auth/register/components/register.form";

interface RequestServiceProps {
}

const colsSpanning = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
}

interface FormValues {
    vehicleType: string;
    make: string;
    variant: string;
    model: string;
    gear: string;
    year: string;
    size: string;
    color: string;
    serviceType: string;
    subServiceType: string;
}
const ServiceRequestForm: FC<RequestServiceProps> = () => {

    const [form] = Form.useForm<FormValues>();
    const dispatcher = useAppDispatch();

    const onFinish = async (values: FormValues) => {
        try {
            const res = await dispatcher(createServiceRequestAsync({
                payload: {
                    vehicleType: values.vehicleType,
                    make: values.make,
                    model: values.model,
                    variant: values.variant,
                    gear: values.gear,
                    year: values.year,
                    size: values.size,
                    color: values.color,
                    serviceType: values.serviceType,
                    subServiceType: values.subServiceType
                }
            }))

            if (res.meta.requestStatus === 'fulfilled') {
                message.success("Request is registered");
            }

            if (res.meta.requestStatus === 'rejected') {
                message.error({
                    content: 'There was problem while regestering request. Please try again!!',
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };



    return (
        <Row style={{ padding: '2rem' }}>
            <Col span={24}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size="large"
                >
                    <Row gutter={[10, 0]}>
                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Vehicle Type"
                                name="vehicleType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter vehicle type',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Make"
                                name="make"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter make',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Model"
                                name="model"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter model',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Variant"
                                name="variant"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter variant',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Gear"
                                name="gear"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter gear',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Year"
                                name="year"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter year',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Size"
                                name="size"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter size',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Color"
                                name="color"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter color',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Service"
                                name="serviceType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter service',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col {...colsSpanning}>
                            <Form.Item
                                label="Sub Service"
                                name="subServiceType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter sub service',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>


                        <Col span={24} className="mt-3">
                            <Space className="flex-wrap">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Request Service
                                </Button>
                                <Button
                                    type="default"
                                    htmlType="reset"
                                >
                                    Reset
                                </Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

export default ServiceRequestForm;