class ls {
  // constructor() {}

  static get = (key: string) => {
    let value: any = localStorage.getItem(key);
    return JSON.parse(value);
  };

  static set = (key: string, value: any) => {
    if (value === null || value === undefined) {
      return;
    }
    localStorage.setItem(key, JSON.stringify(value));
  };

  static remove = (key: string) => {
    localStorage.removeItem(key);
  };

  static clear = () => {
    localStorage.clear();
  };
}

export default ls;
