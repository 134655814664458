import React, { FC, useState } from "react";
import { Button, Col, Divider, Form, Image, Input, Modal, Row, Select, Space, Tooltip, Typography } from "antd";
import { Business } from "../../../../services/business/list-business.service";
import { EyeOutlined } from "@ant-design/icons";
import Country from "../../../../utils/countryList";
import { useAppSelector } from "../../../../store/hooks";
import { constantsSelector } from "../../../../store/constants/constantsSlice";
import config from "../../../../config/config";

interface ViewBusinessModalProps {
  business: Business
}

export interface FormDataType {
  businessName: string;
  businessRole: number;
  photos?: File[]
  phoneNumber: string;
  email: string;
  storeAddress: string;
  zipCode: string;
  country: string;
  state: string;
  city: string;
  latitude: string;
  longitude: string;
  referralCode?: string;
  users: {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    dinNo?: string,
    email: string,
    photo?: File
  }[],
  gstNumber?: string;
  panNumber?: string;
  legalDocuments?: {
    type: string;
    file: File
  }[]
}

const columnFlex = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  sl: 12,
};

const ViewBusinessModal: FC<ViewBusinessModalProps> = ({ business }) => {

  const [form] = Form.useForm<FormDataType>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const businessRoles = useAppSelector(constantsSelector.listBusinessRoles);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values: FormDataType) => {
    console.log(values);
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const longitudeValidator = (rule: any, value: any) => {
    if (!value) {
      return Promise.reject('Longitude is required');
    }

    if (value < -180) {
      return Promise.reject('Longitude must be greater than -180');
    }

    if (value > 180) {
      return Promise.reject('Longitude must be less than 180');
    }
    return Promise.resolve();
  };

  const latitudeValidator = (rule: any, value: any) => {
    if (!value) {
      return Promise.reject('Latitude is required');
    }

    if (value < -90) {
      return Promise.reject('Latitude must be greater than -90');
    }

    if (value > 90) {
      return Promise.reject('Latitude must be less than 90');
    }

    return Promise.resolve();
  };

  return (
    <>
      <Tooltip title="View Details">
        <Button type="primary" icon={<EyeOutlined />} onClick={showModal} />
      </Tooltip>
      <Modal
        title="Business"
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        okText="Submit"
        width={1000}
        style={{ top: 20 }}
        bodyStyle={{ height: 'calc(100vh - 40px - 110px)', overflow: 'auto' }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
          initialValues={{
            ...business,
            businessRole: business.businessRoleId,
            location: `${business.latitude}, ${business.longitude}`,
          }}
        >
          <Row gutter={[10, 0]} className="text-start">
            <Col span={24}>
              <Form.Item
                label="Business Full Name"
                name="businessName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter business name',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Business Type"
                name="businessRole"
                rules={[
                  {
                    required: true,
                    message: 'Please select Business Type!',
                  },
                ]}
              >
                <Select allowClear disabled>
                  {businessRoles.map((o) => (
                    <Select.Option key={o.id} value={o.id}>
                      {o.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col {...columnFlex}>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                // initialValue={`${initialValue.phone.dialCode}${initialValue.phone.number}`}
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...columnFlex}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                  {
                    type: 'email',
                    message: 'Please enter valid email',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Store Address"
                name="storeAddress"
                rules={[
                  {
                    required: true,
                    message: 'Please enter store address',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col {...columnFlex}>
              <Form.Item
                label="ZipCode"
                name="zipCode"
                rules={[{ required: true, message: 'Please enter zip code' }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col {...columnFlex}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: 'Please select country' }]}
              >
                <Select
                  disabled
                  style={{ width: '100%' }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={Country.getList().map(country => ({
                    value: country.code,
                    label: country.name,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col {...columnFlex}>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: 'Please enter city' }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col {...columnFlex}>
              <Form.Item
                label="Latitude"
                name="latitude"
                rules={[
                  { validator: latitudeValidator }
                ]}
              >
                <Input type="number" disabled />
              </Form.Item>
            </Col>

            <Col {...columnFlex}>
              <Form.Item
                label="Longitude"
                name="longitude"
                rules={[
                  { validator: longitudeValidator }
                ]}
              >
                <Input type="number" disabled />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Select Location"
                name="location"
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Photos">
                {business.BusinessBanner.map(image => (
                  <Image
                    key={image.id}
                    style={{ borderRadius: 4 }}
                    width={200}
                    src={`${config.NODE_IMAGE_PATH}registration/${image.path}`}
                  />
                ))}
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginTop: "3rem" }}>
              <Typography.Title level={3}>
                Owner / Partner Details
              </Typography.Title>
            </Col>

            {business.UserOnBusiness.map((user, i) => (
              <React.Fragment key={i}>
                <Divider />
                <Col span={8}>
                  <Form.Item label="First Name">
                    <Input disabled value={user.user.firstName} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Last Name">
                    <Input disabled value={user.user.lastName} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Phone Number">
                    <Input disabled value={user.user.phoneNumber} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Email">
                    <Input disabled value={user.user.email} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="DIN Number">
                    <Input disabled value={user.dinNo || '-'} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="User Photo">
                    <Image
                      style={{ borderRadius: 4 }}
                      width={200}
                      src={`${config.NODE_IMAGE_PATH}registration/${user.photo}`}
                    />
                  </Form.Item>
                </Col>
              </React.Fragment>
            ))}

            <Divider />

            <Col span={24}>
              <Form.Item label="Business Documents">
                <Space>
                  {business.BusinessDocument.map(image => (
                    <Image
                      key={image.id}
                      style={{ borderRadius: 4 }}
                      width={200}
                      src={`${config.NODE_IMAGE_PATH}registration/${image.path}`}
                    />
                  ))}
                </Space>
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ViewBusinessModal;