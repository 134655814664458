import React, { FC, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Space, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import PAGES_PATHS from '../../../utils/constants/pages-path.constants';
import { AxiosResponse } from 'axios';
import sendOtp from '../../../services/auth/send-otp';
import { countries } from '../../../utils/countryList';

interface RegisterProps { }

const { Option } = Select;

const PhoneRegistration: FC<RegisterProps> = () => {
  const [submitButtonText, setSubmitButtonText] = useState('Send otp');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    setSubmitButtonText('Sending Otp');
    setLoading(true);
    try {
      console.log(values.dialCode);
      let res: AxiosResponse = await sendOtp({
        body: {
          phoneNumber: `${values.dialCode}${values.phoneNumber}`,
        },
      });
      setSubmitButtonText('Otp Sent');
      setLoading(false);
      navigate('/' + PAGES_PATHS.VERIFY_OTP, {
        state: {
          phoneNumber: values.phoneNumber,
          dialCode: values.dialCode,
        },
      });
    } catch (error: any) {
      setSubmitButtonText('Send otp');
      setLoading(false);
      console.log(error);
      if (error?.response) {
        message.error(error.response.data.message);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const prefixSelector = (
    <Form.Item name="dialCode" noStyle>
      <Select style={{ width: 85 }}>
        {countries.map((country, index) => (
          <Option key={index} value={country.dialCode}>
            {country.dialCode}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const submitButton = (
    <Button type="primary" htmlType="submit" block disabled={loading}>
      {submitButtonText}
    </Button>
  );
  return (
    <Row className="h-100 align-items-center" justify="center">
      <Col xs={{ span: 22 }} sm={{ span: 20 }} md={{ span: 18 }}>
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <Row
            className="bg-white"
            style={{
              boxShadow: '0px 15px 32px rgba(0, 0, 0, 0.1)',
              borderRadius: 32,
              padding: '42px 41px',
            }}
            justify="center"
          >
            <Col span={24}>
              <div className="text-center">
                <Typography.Title level={2}>Signup</Typography.Title>
              </div>
            </Col>
            <Col span={24}>
              <div className="text-center">
                <Typography>Login to discover amazing things near around you</Typography>
              </div>
            </Col>
            <Col span={16} className="mt-5 text-start">
              <Form
                layout="vertical"
                initialValues={{
                  phone: '',
                  dialCode: '+91',
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Form.Item
                      name="phoneNumber"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your phone number!',
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: 'Phone number can only include numbers.',
                        },
                      ]}
                    >
                      <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>{submitButton}</Col>

                  <Col span={24}>
                    <Typography className="mt-3 text-center">
                      Already have an account? <Link to={'/' + PAGES_PATHS.LOGIN}>Sign In</Link>{' '}
                      here
                    </Typography>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default PhoneRegistration;
