import axios from 'axios';
import {BusinessRole} from '../../store/constants/constantsSlice';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';
import {User} from "../../store/user/userSlice";

export interface ListBusinessApi {
  queryParams?: {
    currentPage?: number;
    pageSize?: number;
    as?: 'csv';
  };
}

export interface DocumentInterface {
  businessId: number
  id: number
  path: string
}

export interface BusinessUser {
  id: number
  businessId: number
  user: User
  dinNo: string | null
  photo: string
  userId: number
  userRoleId: number
  updatedAt: string
  createdAt: string
}

export type ApprovalStatus = {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type Business = {
  id: number;
  phoneNumber: string;
  businessName: string;
  email: string;
  businessRoleId: number;
  storeAddress: string;
  city: string;
  country: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  isPhoneVerified: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  businessRole?: BusinessRole;
  approvalStatusId: number;
  approvalStatus: ApprovalStatus;
  BusinessBanner: DocumentInterface[]
  BusinessDocument: DocumentInterface[]
  UserOnBusiness: BusinessUser[]
};

const listBusinesses = (args?: ListBusinessApi) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.BUSINESS;

  let queryParams = args?.queryParams || {};

  return axios.get(url, {
    params: queryParams,
  });
};

export default listBusinesses;
