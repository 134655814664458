import React, { FC, useEffect, useState } from 'react';
import { Anchor, Badge, Button, Col, Form, Input, message, Row, Space, Tag, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import PAGES_PATHS from '../../../utils/constants/pages-path.constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearError, setBusiness, userSelectors } from '../../../store/user/userSlice';
import userActions, { getUserRoleAsync, listUserRolesAsync, loginAsync } from '../../../store/user/userActions';
import APP_CONSTANTS from '../../../utils/constants/app.constants';
import { Business } from '../../../services/business/list-business.service';
import ls from '../../../utils/helpers/local-storage';
import BoxCard from '../../../components/cards/box.card';

interface LoginProps { }

const Login: FC<LoginProps> = () => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState<1 | 2>(1);
  const { login: loginLoader } = useAppSelector(userSelectors.getLoading);
  const user = useAppSelector(userSelectors.getUser);
  const userRoles = useAppSelector(userSelectors.listUserRoles);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const { login: loginError } = useAppSelector(userSelectors.getError);

  useEffect(() => {
    return () => {
      //cleaning redux store
      dispatcher(clearError());
    };
  }, []);

  useEffect(() => {
    if (loginError) {
      message.error(loginError.data.message);
    }
  }, [loginError]);

  const onFinish = async (values: any) => {
    dispatcher(
      loginAsync({
        email: values.email,
        password: values.password,
      }),
    ).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        // if (res.payload.user.userRoleId === 5) {
        //   navigate(`/${PAGES_PATHS.SERVICE_IN_GARAGE}`);
        //   return;
        // }
        if (res.payload.user.isAdmin) {
          navigate(`/${PAGES_PATHS.DASHBOARD}`);
          return;
        }
        dispatcher(listUserRolesAsync({
          params: {
            id: res.payload.user.id
          }
        }))
        setStep(2);
      }

    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleUserRoleSelect = async (roleId: number) => {
    if (user === null) {
      return;
    }

    dispatcher(userActions.listBusinessAsync({
      params: {
        id: user.id
      },
      query: {
        businessRole: APP_CONSTANTS.BUSINESS_ROLE_USER_ROLE[roleId]
      }
    })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setBusinesses(res.payload.data);
      }
    });
  }

  const handleBusinessSelect = (business: Business) => {
    if (user === null) {
      return;
    }

    ls.set(APP_CONSTANTS.LOCAL_STORAGE_KEYS.BUSINESS, business);
    dispatcher(setBusiness(business));

    dispatcher(getUserRoleAsync({
      params: {
        id: user.id
      },
      query: {
        business: business.id
      }
    })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        navigate('/' + PAGES_PATHS.SERVICE_IN_GARAGE);
      }
    });
  }

  return (
    <Row className="h-100 align-items-center" justify="center">
      <Col xs={{ span: 22 }} sm={{ span: 20 }} md={{ span: 18 }}>
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <BoxCard>
            <Row
              justify="center"
              gutter={[0, 16]}
            >
              {step === 1 && <><Col span={24}>
                <div className="text-center">
                  <Typography.Title level={2} className="mb-0">Sign In</Typography.Title>
                </div>
              </Col>
                <Col span={24}>
                  <div className="text-center">
                    <Typography>Login to discover amazing things near around you</Typography>
                  </div>
                </Col>
                <Col span={16} className="mt-5 text-start">
                  <Form
                    layout="vertical"
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Row gutter={[0, 10]}>
                      <Col span={24}>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your username!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your password!',
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Button type="primary" htmlType="submit" loading={loginLoader} block>
                          Sign In
                        </Button>
                      </Col>

                      <Col span={24}>
                        <Typography className="mt-3 text-center">
                          Don’t have an account?{' '}
                          <Link to={'/' + PAGES_PATHS.PHONE_REGISTRATION}>Sign Up</Link> here
                        </Typography>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </>
              }
              {
                step === 2 && businesses.length === 0 &&
                (
                  <>
                    <Col span={24}>
                      <div className="text-center">
                        <Typography.Title level={2} style={{ margin: 0 }}>
                          I would like to sign in as
                        </Typography.Title>
                      </div>
                    </Col>
                    {userRoles.map((role) => (
                      <Col span={16} key={role.id}>
                        <Button
                          block
                          type={'default'}
                          size="large"
                          onClick={() => {
                            handleUserRoleSelect(role.id)
                          }}
                        >
                          {role.name}
                        </Button>
                      </Col>
                    ))}
                  </>
                )
              }

              {
                step === 2 && businesses.length > 0 && (
                  <>
                    <Col span={24}>
                      <div className="text-center">
                        <Typography.Title level={2} style={{ margin: 0 }}>
                          I would like to sign in as
                        </Typography.Title>
                      </div>
                    </Col>
                    {
                      businesses.map(business => (
                        <Col span={16} key={business.id}>
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <Button
                              block
                              type={'default'}
                              size="large"
                              onClick={() => {
                                handleBusinessSelect(business)
                              }}
                              className={`${business.approvalStatusId !== 1}`}
                            >
                              {business.businessName}
                            </Button>
                            <Tag
                              color='#f50'
                              style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                transform: 'translate(50%, -50%)',
                                transformOrigin: '100% 0%',
                              }}
                            >
                              {
                                business.approvalStatus.name
                              }
                            </Tag>
                          </div>
                        </Col>
                      ))
                    }
                  </>
                )
              }
            </Row>
          </BoxCard>
        </Space>
      </Col>
    </Row >
  );
};

export default Login;
