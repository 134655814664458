import { Col, message, Modal, Row, Typography } from "antd";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { listServiceRequestsAsync } from "../../../store/admin/serviceRequest.actions";
import { useAppDispatch } from "../../../store/hooks";
import ServiceRequestsTable from "./components/service-requests.table";
import { useModel } from "./model";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { setPageTitle } from "../../../store/layout/layoutSlice";


const ServiceRequests = () => {

    const location = useLocation();
    const dispatch = useAppDispatch();
    const { serviceRequests, setServiceRequests } = useModel();

    const handleLocationChange = async () => {
        try {
            const res = await dispatch(listServiceRequestsAsync());


            if (res.meta.requestStatus === 'fulfilled') {
                setServiceRequests?.(res.payload.data);
            }

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        handleLocationChange();
    }, [location]);

    useEffect(() => {
        dispatch(setPageTitle('Service Requests'));
    }, []);


    return (
        <Row className="p-4">
            <Col span={24}>
                <ServiceRequestsTable
                    list={serviceRequests}
                />
            </Col>
        </Row>);
}

export default ServiceRequests;