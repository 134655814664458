import React from 'react';
import './App.less';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, createBrowserRouter, Router, RouterProvider } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import routes from './config/routes';

const App = () => {
  const router = createBrowserRouter(routes);

  return (
    <div className="App">
      {/* <BrowserRouter> */}
      {/* {
          renderRoutes(routes)
        } */}
      {/* </BrowserRouter> */}
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
