import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setPageTitle } from '../../store/layout/layoutSlice';
import { Col, Row } from 'antd';
import SellerList from '../../components/SellerList';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../store/user/userSlice';
import APP_CONSTANTS from '../../utils/constants/app.constants';

interface DashboardProps { }

const Dashboard: FC<DashboardProps> = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.getUser);

  useEffect(() => {
    dispatch(setPageTitle('Dashboard'));
  }, []);

  return (
    <Row style={{ padding: '2rem' }}>
      <Col span={24}>{user?.isAdmin && <SellerList />}</Col>
    </Row>
  );
};

export default Dashboard;
