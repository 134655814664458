import { createAsyncThunk } from '@reduxjs/toolkit';
import createBusiness, { CreateBusinessApi } from '../../services/business/create-business.service';
import listBusinesses, { ListBusinessApi } from '../../services/business/list-business.service';

export const createBusinessAsync = createAsyncThunk(
  'business/create',
  async (payload: CreateBusinessApi, { rejectWithValue }) => {
    try {
      const response = await createBusiness({
        payload: payload.payload,
      });
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const listBusinessAsync = createAsyncThunk(
  'business/create',
  async (payload: ListBusinessApi, { rejectWithValue }) => {
    try {
      const response = await listBusinesses(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

const businessActions = {
  createBusinessAsync,
  listBusinessAsync,
};

export default businessActions;
