import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Col, Input, List, message, Modal, Row, Space, Typography } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "antd-button-color";
import servicePageActions from "../../../../../store/admin/servicePage.actions";
import { addServiceType, servicePageSelector } from "../../../../../store/admin/servicePage.slice";
import { ServiceTypeType } from "../../../../../services/service";

interface ServiceTypeModalProps {
    onCreate?: (serviceType: ServiceTypeType) => void;
}

const CreateServiceType: FC<ServiceTypeModalProps> = ({ onCreate }) => {
    const dispatch = useAppDispatch();

    const serviceTypeList = useAppSelector(servicePageSelector.serviceType.list);
    const [open, setOpen] = useState<boolean>(false);
    const { create: createServiceTypeLoading } = useAppSelector(servicePageSelector.serviceType.loading);
    const { create: createServiceTypeError } = useAppSelector(servicePageSelector.serviceType.error);

    const [newServiceType, setNewServiceType] = useState('');

    const removeServiceType = (id: number) => {
        dispatch(servicePageActions.serviceType.delete({
            id: id
        }))
    }

    const createNewServiceType = () => {
        dispatch(servicePageActions.serviceType.create({
            name: newServiceType
        })).then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
                setNewServiceType('');
                dispatch(addServiceType(res.payload.data));
                onCreate?.(res.payload.data);
            }
        });
    }

    useEffect(() => {
        if (createServiceTypeError) {
            message.error(createServiceTypeError.data.message);
        }
    }, [createServiceTypeError])

    const handleToggleOnClick = () => {
        setOpen(true);
        setNewServiceType('');
    }

    const handleOnCancel = () => {
        setOpen(false);
    }

    return (<>
        <Button
            icon={<PlusOutlined />}
            size="small"
            onClick={handleToggleOnClick}
        >
            Add New
        </Button>
        <Modal
            title="Add Service Type"
            open={open}
            footer={null}
            onCancel={handleOnCancel}
        >
            <Row gutter={[0, 20]}>
                <Col span={24}>
                    <List
                        size="small"
                        dataSource={serviceTypeList}
                        renderItem={item => (
                            <List.Item key={item.id}>
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <Typography>{item.name}</Typography>
                                    <Button
                                        icon={<MinusOutlined />}
                                        size="small"
                                        shape="circle"
                                        type="danger"
                                        onClick={() => removeServiceType(item.id)}
                                    />
                                </div>
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Space direction="vertical" className="w-100">
                        <Input
                            placeholder="Add custom type"
                            value={newServiceType}
                            onChange={(e) => setNewServiceType(e.target.value)}
                        />
                        <Button
                            icon={<PlusOutlined />}
                            onClick={createNewServiceType}
                            loading={createServiceTypeLoading}
                            disabled={newServiceType.length === 0 || createServiceTypeLoading}
                        >
                            Add
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Modal>
    </>);
};

export default CreateServiceType;