import { Card, Checkbox, Col, Row } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FC, useState } from "react"



type Value = number;
type Values = Value[];
export type OptionType = { label: string, value: Value };

interface SelectCheckBoxes {
    value?: Values;
    onChange?: (values: Values) => void;
    options: OptionType[];
    multiSelect?: boolean;
    className?: string
}

const InputCheckBoxes: FC<SelectCheckBoxes> = ({ value, onChange, options, multiSelect = true, className }) => {

    const handleOnChange = (checkedValues: any) => {
        if (multiSelect) {
            onChange?.(checkedValues);
        }

        if (!multiSelect) {
            let checked: Values = [];
            if (checkedValues.length > 0) {
                checked = [checkedValues[checkedValues.length - 1]];
            }
            onChange?.(checked);
        }

        if (multiSelect) {
            setIndeterminate(!!checkedValues.length && checkedValues.length < options.length);
            if (checkedValues.length === options.length) {
                setCheckAll(true);
            }

            if (checkedValues.length === 0) {
                setCheckAll(false);
            }
        }

    }

    const [indeterminate, setIndeterminate] = useState<boolean>(false);
    const [checkAll, setCheckAll] = useState<boolean>(false);

    const handleOnCheckAllChange = (el: CheckboxChangeEvent) => {
        let selectedOptions: Values = [];
        if (el.target.checked === true) {
            selectedOptions = options.map(option => option.value);
        }
        onChange?.(selectedOptions);
        setIndeterminate(false);
        setCheckAll(el.target.checked);
    }


    return (<Card
        title={(
            multiSelect ? <Checkbox
                indeterminate={indeterminate}
                onChange={handleOnCheckAllChange}
                checked={checkAll}>Select all</Checkbox>
                :
                ''
        )}
        className={className}
    >
        <Checkbox.Group className="w-100" value={value} onChange={handleOnChange}>
            <Row gutter={[8, 8]}>
                {
                    options.map((option, index) => (
                        <Col key={index} span={12}>
                            <Checkbox value={option.value}>{option.label}</Checkbox>
                        </Col>
                    ))
                }
            </Row>
        </Checkbox.Group>
    </Card >)
}

export default InputCheckBoxes
