import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

export interface ListUserRolesApi {
  params: {
    id: number;
  };
  query?: {
    business: number;
  };
}
const listUserRoles = (args: ListUserRolesApi) => {
  let url: string =
    BACKEND_PATHS.BASE + BACKEND_PATHS.USERS_ROLES.replace(':id', args.params.id.toString());
  let query: Record<string, any> = {};

  if (args.query) {
    query = args.query;
  }

  return axios.get(url, {
    params: query,
  });
};

export default listUserRoles;
