import config from '../../config/config';

interface BackendPaths {
  BASE: string;
  REGISTER: string;
  LOGIN: string;
  SEND_OTP: string;
  VERIFY_OTP: string;
  DELIVERY_TYPES: string;
  USERS_ROLES: string;
  STORE_TYPES: string;
  GET_SELLER_LIST: string;
  DELETE_SELLERS: string;
  SERVICE: string;
  VEHICLE_TYPE: string;
  MAKE: string;
  MODEL: string;
  VARAINT: string;
  SERVICE_TYPE: string;
  SUB_SERVICE_TYPE: string;
  SERVICE_UNDER_GARAGE: string;
  BUSINESS: string;
  BUSINESS_ROLE: string;
  BUSINESS_ROLE_BY_USER: string;
  SERVICE_REQUEST: string;
  SIZE: string;
  GEAR: string;
  YEAR: string;
  COLOR: string;
}

const BACKEND_PATHS: BackendPaths = {
  BASE: config.BACKEND_BASE,
  REGISTER: 'register/',
  LOGIN: 'login/',
  SEND_OTP: 'register/send-otp/',
  VERIFY_OTP: 'register/verify-otp/',
  DELIVERY_TYPES: 'delivery-types/',
  USERS_ROLES: 'users/:id/roles/',
  STORE_TYPES: 'store-types',
  GET_SELLER_LIST: 'sellers/',
  DELETE_SELLERS: 'sellers/bulk/delete',
  SERVICE: 'services/',
  VEHICLE_TYPE: 'vehicle-types/',
  MAKE: 'makes/',
  MODEL: 'models/',
  VARAINT: 'variants/',
  SERVICE_TYPE: 'service-types/',
  SUB_SERVICE_TYPE: 'sub-service-types/',
  SERVICE_UNDER_GARAGE: 'garages/:garageId/services/',
  BUSINESS: 'businesses/',
  BUSINESS_ROLE: 'business-roles/',
  BUSINESS_ROLE_BY_USER: 'users/:id/businesses/',
  SERVICE_REQUEST: 'service-requests/',
  SIZE: 'sizes/',
  GEAR: 'gears/',
  YEAR: 'years/',
  COLOR: 'colors/',
};

export default BACKEND_PATHS;
