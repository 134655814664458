import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface LayoutState {
  isSideBarOpen: boolean;
  pageTitle: string;
}

const initialState: LayoutState = {
  isSideBarOpen: true,
  pageTitle: '',
};

const LayoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean>) => {
      state.isSideBarOpen = action.payload;
    },
    setPageTitle: (state, action: PayloadAction<string>) => {
      state.pageTitle = action.payload;
    },
  },
  extraReducers: undefined,
});

export const { toggleSidebar, setPageTitle } = LayoutSlice.actions;

export const layoutSelectors = {
  getSidebarOpen: (state: RootState) => state.layout.isSideBarOpen,
  getPageTitle: (state: RootState) => state.layout.pageTitle,
};

export default LayoutSlice.reducer;
