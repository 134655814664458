import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Col, Input, List, message, Modal, Row, Space, Typography } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "antd-button-color";
import servicePageActions from "../../../../../store/admin/servicePage.actions";
import { addVehicle, servicePageSelector } from "../../../../../store/admin/servicePage.slice";
import { VehicleTypeType } from "../../../../../services/service";

interface AddVehicleTypeModalProps {
  onCreate?: (vehicleType: VehicleTypeType) => void;
}

const CreateVehicleTypeModal: FC<AddVehicleTypeModalProps> = ({ onCreate }) => {

  const dispatch = useAppDispatch();

  const vehicleTypeList = useAppSelector(servicePageSelector.vehicleType.list);
  const [open, setOpen] = useState<boolean>(false);

  const { create: addVehicleTypeLoading } = useAppSelector(servicePageSelector.vehicleType.loading);
  const { create: addVehicleTypeError } = useAppSelector(servicePageSelector.vehicleType.error);

  const [newVehicleType, setNewVehicleType] = useState('');

  const removeVehicleType = (id: number) => {
    dispatch(servicePageActions.vehicleType.delete({
      id: id
    }))
  };

  const createVehicleType = () => {
    dispatch(servicePageActions.vehicleType.create({
      name: newVehicleType
    })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setNewVehicleType('');
        dispatch(addVehicle(res.payload.data))
        onCreate?.(res.payload.data);
      }
    });
  }

  useEffect(() => {
    if (addVehicleTypeError) {
      message.error(addVehicleTypeError.data.message);
    }
  }, [addVehicleTypeError]);


  const handleToogleOnClick = () => {
    setOpen(true);
    setNewVehicleType('');
  };

  const handleOnCancel = () => {
    setOpen(false);
  };

  return (<>
    <Button
      icon={<PlusOutlined />}
      size="small"
      onClick={handleToogleOnClick}
    >
      Add New
    </Button>
    <Modal
      title="Add Vehicle Types"
      open={open}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <List
            size="small"
            dataSource={vehicleTypeList}
            renderItem={item => (
              <List.Item key={item.id}>
                <div className='d-flex w-100 align-items-center justify-content-between'>
                  <Typography>{item.name}</Typography>
                  <Button
                    icon={<MinusOutlined />}
                    size="small"
                    shape="circle"
                    type="danger"
                    onClick={() => removeVehicleType(item.id)}
                  />
                </div>
              </List.Item>
            )}
          />
        </Col>
        <Col span={24}>
          <Space direction="vertical" className="w-100">
            <Input
              placeholder="Add custom type"
              value={newVehicleType}
              onChange={(e) => setNewVehicleType(e.target.value)}
            />
            <Button
              icon={<PlusOutlined />}
              onClick={createVehicleType}
              loading={addVehicleTypeLoading}
              disabled={newVehicleType.length === 0 || addVehicleTypeLoading}
            >
              Add
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  </>);
};

export default CreateVehicleTypeModal;