import { RouteConfig } from 'react-router-config';
import { RouteObject } from 'react-router-dom';
import SplashLayout from '../layout/SplashLayout';
import Login from '../pages/auth/login';
import DefaultPage from '../pages/default';
import PAGES_PATHS from '../utils/constants/pages-path.constants';
import Registration from '../pages/auth/register';
import EnterOtpForm from '../pages/auth/phone-verification';
import UserRoleForm from '../pages/auth/register';
import PhoneRegistration from '../pages/auth/phone-registration';
import ThankYouPage from '../pages/ThankYouPage';
import Dashboard from '../pages/dashboard';
import BasicLayout from '../layout/basic.layout';
import AuthComponent from '../components/auth.component';
import Service from '../pages/services/admin';
import Garage from '../pages/services/garage';
import { ModelProvider } from '../pages/services/garage/model';
import ServiceRequestPage from '../pages/service-request/garage';
import ServiceList from '../pages/services/admin/components/list/ServiceList.table';
import ServiceRequests from '../pages/service-request/admin';
import { ModelProvider as AdminServiceRequestModelProvider } from '../pages/service-request/admin/model';


const routes: RouteObject[] = [
  {
    element: <SplashLayout />,
    path: '/',
    children: [
      {
        element: <Login />,
        path: PAGES_PATHS.LOGIN,
      },
      {
        element: <Registration />,
        path: PAGES_PATHS.REGISTER,
      },
      {
        element: <PhoneRegistration />,
        path: PAGES_PATHS.PHONE_REGISTRATION,
      },
      {
        element: <EnterOtpForm />,
        path: PAGES_PATHS.VERIFY_OTP,
      },
      {
        element: <DefaultPage />,
        path: '',
      },
    ],
  },
  {
    element: <AuthComponent />,
    path: '/',
    children: [
      {
        element: <BasicLayout />,
        path: '/',
        children: [
          {
            element: <Dashboard />,
            path: '/dashboard',
          },
          {
            path: '/admin',
            children: [
              {
                path: 'services',
                element: <Service />
              }
            ]
          },
          {
            path: '/garage',
            children: [
              {
                path: 'services',
                children: [
                  {
                    path: '',
                    element: <ModelProvider><Garage /></ModelProvider>,
                  },
                  {
                    path: 'request',
                    element: <ServiceRequestPage />,
                  }
                ]
              }
            ]
          },
          {
            path: '/admin',
            children: [
              {
                path: 'services',
                children: [
                  {
                    path: 'request',
                    element: <AdminServiceRequestModelProvider><ServiceRequests /></AdminServiceRequestModelProvider>,
                  },
                ]
              }
            ]
          }
        ],
      },
    ],
  },
  {
    element: <ThankYouPage />,
    path: PAGES_PATHS.THANK_YOU,
  },
  {
    element: <DefaultPage />,
    path: '',
  },
];

export default routes;
