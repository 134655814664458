import React, { FC } from 'react';
import { Typography } from 'antd';

interface ThankYouPageProps {}

const ThankYouPage: FC<ThankYouPageProps> = () => {
  return (
    <div className="h-100vh w-100vw d-flex align-items-center justify-content-center">
      <Typography.Title level={1}>Thank You For Registering.</Typography.Title>
    </div>
  );
};

export default ThankYouPage;
