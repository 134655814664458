import { Col, Row } from "antd";
import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { setPageTitle } from "../../../store/layout/layoutSlice";
import AddServiceForm from "./components/forms/AddService.form";
import ServiceList from "./components/list/ServiceList.table";

interface ServiceProps {

}
const Service: FC<ServiceProps> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Add Services'));
  }, []);

  return (
    <Row style={{ padding: '2rem' }}>
      <Col span={24}>
        <AddServiceForm />
      </Col>
      <Col span={24}>
        <ServiceList />
      </Col>
    </Row>
  );
};

export default Service;