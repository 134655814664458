interface PagePathsInterface {
  DEFAULT: string;
  LOGIN: string;
  REGISTER: string;
  // SEND_OTP: string;
  VERIFY_OTP: string;
  // SELECT_USER_ROLE: string;
  PHONE_REGISTRATION: string;
  THANK_YOU: string;
  DASHBOARD: string;
  SERVICE_IN_GARAGE: string;
}
const PAGES_PATHS: PagePathsInterface = {
  DEFAULT: 'login/',

  LOGIN: 'login/',
  REGISTER: 'register/user-form',
  PHONE_REGISTRATION: 'register/phone-registration/',
  VERIFY_OTP: 'register/phone-verification/',
  THANK_YOU: 'thank-you/',
  DASHBOARD: 'dashboard/',
  SERVICE_IN_GARAGE: 'garage/services/',
  // SELECT_USER_ROLE: "select-user-role/",
};

export default PAGES_PATHS;
