import { Input, Radio, Typography, RadioChangeEvent, InputNumber } from "antd";
import { FC, useState } from "react";
import { FormLabel } from "../form.label";


export type Value = number;

interface TimeInput {
    value?: Value,
    onChange?: (value: Value) => {}
}

type OptionType = {
    label: string;
    value: string;
}


const TimeInput: FC<TimeInput> = ({ value, onChange }) => {
    const [optoins, setOptions] = useState<OptionType[]>([
        { label: 'Days', value: 'days' },
        { label: 'Hrs', value: 'hrs' }
    ]);

    const [selectedTimeType, setSelectedTimeType] = useState<'days' | 'hrs'>('days');
    const [days, setDays] = useState<number | null>(null);
    const [hrs, setHrs] = useState<number | null>(null);
    const [mins, setMins] = useState<number | null>(null);

    const tranformHrsToSecs = (hrs: number) => {
        return hrs * 60 * 60;
    }

    const tranformMinsToSecs = (mins: number) => {
        return mins * 60;
    }

    const tranformDaysToSecs = (days: number) => {
        return days * 24 * 60 * 60;
    }

    const handleTimeTypeChange = (e: RadioChangeEvent) => {
        setSelectedTimeType(e.target.value);
    }

    const handleDaysChange = (days: number | null) => {
        setDays(days);
        const totalMiliseconds = tranformDaysToSecs(days || 0);
        onChange?.(totalMiliseconds)
    }

    const handleHoursChange = (hrs: number | null) => {
        setHrs(hrs);
        const totalMiliseconds = tranformHrsToSecs(hrs || 0) + tranformMinsToSecs(mins || 0);
        onChange?.(totalMiliseconds)
    }

    const handleMinsChange = (mins: number | null) => {
        setMins(mins);
        const totalMiliseconds = tranformHrsToSecs(hrs || 0) + tranformMinsToSecs(mins || 0);
        onChange?.(totalMiliseconds)
    }

    const DaysTimeInputUI = (<div>
        <div className="d-flex align-items-center col-gap-1">
            <Typography.Text>Days:</Typography.Text>
            <InputNumber<number>
                style={{
                    width: '100px'
                }}
                onChange={handleDaysChange}
                min={0}
            />
        </div>
    </div>)

    const HrsMinsTimeInputUI = (<div className="d-flex align-items-center col-gap-2">
        <div className="d-flex align-items-center col-gap-1">
            <Typography.Text>Hrs:</Typography.Text>
            <InputNumber
                style={{
                    width: '100px'
                }}
                onChange={handleHoursChange}
                min={0}
            />
        </div>
        <div className="d-flex align-items-center col-gap-1">
            <Typography.Text>Mins:</Typography.Text>
            <InputNumber
                style={{
                    width: '100px'
                }}
                onChange={handleMinsChange}
                min={0}
            />
        </div>
    </div>)

    return (
        <>
            <div className="mb-3">
                <Radio.Group
                    options={optoins}
                    value={selectedTimeType}
                    onChange={handleTimeTypeChange}
                />
            </div>
            {
                selectedTimeType === 'days' && DaysTimeInputUI
            }
            {
                selectedTimeType === 'hrs' && (<div>
                    {HrsMinsTimeInputUI}
                </div>)}
        </>
    )

}

export default TimeInput;