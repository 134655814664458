import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import { FC, Key, useEffect, useState } from "react";
import { ServiceType } from "../../../../services/service";

interface TableRecord {
    key: Key;
    serviceType: string;
    subServiceType: string;
    urbanCost: number;
    ruralCost: number;
    metroCost: number;
}

type Value = number;
type Values = Value[];

interface InputTableInterface {
    value?: Values;
    onChange?: (value: Values) => void;
    list: any[];
}

const InputTable: FC<InputTableInterface> = ({ value, onChange, list }) => {

    const [dataSource, setDataSource] = useState<TableRecord[]>();

    const columns: ColumnsType<TableRecord> = [
        {
            title: 'All Select',
            dataIndex: 'serviceType'
        },
        {
            title: 'Sub Service',
            dataIndex: 'subServiceType'

        },
        {
            title: 'Urban(Price)',
            dataIndex: 'urbanCost'

        },
        {
            title: 'Rural(Price)',
            dataIndex: 'ruralCost'
        },
        {
            title: 'metro(Price)',
            dataIndex: 'metroCost'
        }
    ];

    const handleOnSelect = (selectedRowKeys: React.Key[], selectedRows: TableRecord[]) => {
        onChange?.(selectedRowKeys as Values);
    }

    const rowSelection: {
        type: 'checkbox',
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableRecord[]) => void
    } = {
        type: 'checkbox',
        onChange: handleOnSelect,
    };

    const createDataSource = (list: ServiceType[]) => {
        return list.map((item) => {
            return {
                key: item.id,
                serviceType: item.serviceType?.name || '',
                subServiceType: item.subServiceType?.name || '',
                urbanCost: item.urbanCost,
                metroCost: item.metroCost,
                ruralCost: item.ruralCost
            } as TableRecord
        });
    }

    useEffect(() => {
        setDataSource(createDataSource(list));
    }, [list]);

    return (<>
        <Table
            dataSource={dataSource}
            rowSelection={{
                ...rowSelection
            }}
            columns={columns}
            pagination={false}
        />
    </>)
}

export default InputTable;