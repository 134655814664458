import { Button, Col, Form, message, Row } from "antd";
import axios, { AxiosResponse } from "axios";
import { FC, useEffect, useState } from "react";
import { FormLabel } from "../../../../components/forms/form.label";
import { createServiceInGarage, listColor, listGears, listMakes, listModels, listServiceInGarage, listServices, listSizes, listVariants, listYears } from "../../../../services/service";
import { useAppSelector } from "../../../../store/hooks";
import { userSelectors } from "../../../../store/user/userSlice";
import APP_CONSTANTS from "../../../../utils/constants/app.constants";
import { useModel } from "../model";
import InputCheckBoxes, { OptionType } from "./input.checkbox";
import InputTable from "./service-input.table";


const ServiceForm: FC = () => {
    const [vehicleTypeOptions, setVehicleTypeOptions] = useState<OptionType[]>([]);
    const [makeOptions, setMakeOptions] = useState<OptionType[]>([]);
    const [modelOptions, setModelOptions] = useState<OptionType[]>([]);
    const [variantOptions, setVariantOptions] = useState<OptionType[]>([]);
    const [sizeOptions, setSizeOptions] = useState<OptionType[]>([]);
    const [gearOptions, setGearOptions] = useState<OptionType[]>([]);
    const [yearOptions, setYearOptions] = useState<OptionType[]>([]);
    const [colorOptions, setColorOptions] = useState<OptionType[]>([]);
    const user = useAppSelector(userSelectors.getUser);
    const business = useAppSelector(userSelectors.getBusiness);
    const {
        vehicleTypes, setVehicleTypes,
        makes, setMakes,
        models, setModels,
        variants, setVariants,
        services, setServices,
        sizes, setSizes,
        gears, setGears,
        years, setYears,
        colors, setColors,
        servicesInGarage, setServicesInGarage,
        selectedRecordID, setSelectedRecordID
    } = useModel();

    const [form] = Form.useForm();

    const handleValuesChange = async (changedValues: any, values: any) => {
        if ('vehicleType' in changedValues) {
            try {
                let makesRes: AxiosResponse<any, any> = await listMakes({
                    query: {
                        vehicleTypes: [changedValues.vehicleType[0]]
                    }
                });
                setMakes?.(makesRes.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        if ('make' in changedValues) {
            try {
                let modelsRes: AxiosResponse<any, any> = await listModels({
                    query: {
                        vehicleTypes: values.vehicleType,
                        makes: changedValues.make
                    }
                });
                setModels?.(modelsRes.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        if ('model' in changedValues) {
            try {
                let variantsRes: AxiosResponse<any, any> = await listVariants({
                    query: {
                        vehicleTypes: values.vehicleType,
                        makes: values.make,
                        models: changedValues.model,
                    }
                });
                setVariants?.(variantsRes.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        if ('variant' in changedValues) {
            try {
                let sizesRes: AxiosResponse<any, any> = await listSizes({
                    query: {
                        vehicleTypes: values.vehicleType,
                        makes: values.make,
                        models: values.model,
                        variants: changedValues.variant,
                    }
                });
                setSizes?.(sizesRes.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        if ('size' in changedValues) {
            try {
                let gearsRes: AxiosResponse<any, any> = await listGears({
                    query: {
                        vehicleTypes: values.vehicleType,
                        makes: values.make,
                        models: values.model,
                        variants: values.variant,
                        sizes: changedValues.size,
                    }
                });
                setGears?.(gearsRes.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        if ('gear' in changedValues) {
            try {
                let yearsRes: AxiosResponse<any, any> = await listYears({
                    query: {
                        vehicleTypes: values.vehicleType,
                        makes: values.make,
                        models: values.model,
                        variants: values.variant,
                        sizes: values.size,
                        gears: changedValues.gear,
                    }
                });
                setYears?.(yearsRes.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        if ('year' in changedValues) {
            try {
                let colorsRes: AxiosResponse<any, any> = await listColor({
                    query: {
                        vehicleTypes: values.vehicleType,
                        makes: values.make,
                        models: values.model,
                        variants: values.variant,
                        sizes: values.size,
                        gears: values.gear,
                        years: changedValues.year,
                    }
                });
                setColors?.(colorsRes.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        if ('color' in changedValues) {
            try {
                let servicesRes: AxiosResponse<any, any> = await listServices({
                    query: {
                        vehicleTypes: values.vehicleType,
                        makes: values.make,
                        models: values.model,
                        variants: values.variant,
                        sizes: values.size,
                        gears: values.gear,
                        years: values.year,
                        colors: changedValues.color,
                    }
                });
                setServices?.(servicesRes.data.data);
            } catch (error) {
                console.log(error);
            }
        }

    }

    const handleSubmit = async () => {
        const values = await form.validateFields();

        if (user === null || business === null) {
            return;
        }

        let res: AxiosResponse<any, any> | undefined = undefined;

        try {
            res = await createServiceInGarage({
                garageId: business.id,
                payload: {
                    service: values.service,
                    isBulk: true,
                }
            });
            message.success('Services are created');
        } catch (error) {
            if (axios.isAxiosError(error)) {
                message.error(error.response?.data?.detail || APP_CONSTANTS.ERROR.MESSAGE.DEFAULT);
            }
        }

        if (res === undefined) {
            return;
        }

        try {
            let servicesInGarageRes: AxiosResponse<any, any> = await listServiceInGarage({
                garageId: business.id
            });
            setServicesInGarage?.(servicesInGarageRes.data.data);
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        setVehicleTypeOptions(vehicleTypes.map((vehicleType) => ({ label: vehicleType.name, value: vehicleType.id })));
    }, [vehicleTypes]);

    useEffect(() => {
        setMakeOptions(makes.map((make) => ({ label: make.name, value: make.id })));
    }, [makes]);

    useEffect(() => {
        setModelOptions(models.map((model) => ({ label: model.name, value: model.id })));
    }, [models]);

    useEffect(() => {
        setVariantOptions(variants.map((variant) => ({ label: variant.name, value: variant.id })));
    }, [variants]);

    useEffect(() => {
        setSizeOptions(sizes.map((size) => ({ label: size.name, value: size.id })));
    }, [sizes]);

    useEffect(() => {
        setGearOptions(gears.map((gear) => ({ label: gear.name, value: gear.id })));
    }, [gears]);

    useEffect(() => {
        setYearOptions(years.map((year) => ({ label: year.name, value: year.id })));
    }, [years]);

    useEffect(() => {
        setColorOptions(colors.map((color) => ({ label: color.name, value: color.id })));
    }, [colors]);


    return (<>
        <Form
            layout="vertical"
            form={form}
            onValuesChange={handleValuesChange}
            initialValues={{
                vehicleType: [],
                make: [],
                model: [],
                variant: [],
                serviceType: [],
                subServiceType: []
            }}
        >
            <Row gutter={[10, 10]}>
                <Col span={12}>
                    <Form.Item
                        name='vehicleType'
                        label={<FormLabel>Vehicle Type</FormLabel>}
                    >
                        <InputCheckBoxes
                            options={vehicleTypeOptions}
                            multiSelect={false}
                            className="h-200px max-h-200px"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={<FormLabel>Make</FormLabel>}
                        name='make'
                    >
                        <InputCheckBoxes
                            options={makeOptions}
                            className="h-200px max-h-200px"
                        />

                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='model'
                        label={<FormLabel>Model</FormLabel>}
                        className="h-200px max-h-200px"
                    >
                        <InputCheckBoxes
                            options={modelOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='variant'
                        label={<FormLabel>Variant</FormLabel>}
                        className="h-200px max-h-200px"
                    >
                        <InputCheckBoxes
                            options={variantOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='size'
                        label={<FormLabel>Size</FormLabel>}
                        className="h-200px max-h-200px"
                    >
                        <InputCheckBoxes
                            options={sizeOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='gear'
                        label={<FormLabel>Gear</FormLabel>}
                        className="h-200px max-h-200px"
                    >
                        <InputCheckBoxes
                            options={gearOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='year'
                        label={<FormLabel>Year</FormLabel>}
                        className="h-200px max-h-200px"
                    >
                        <InputCheckBoxes
                            options={yearOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='color'
                        label={<FormLabel>Color</FormLabel>}
                        className="h-200px max-h-200px"
                    >
                        <InputCheckBoxes
                            options={colorOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name='service'
                        label={<FormLabel>Service</FormLabel>}
                    >
                        <InputTable
                            list={services}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Button onClick={handleSubmit} type="primary" size="large">
                        Save Services
                    </Button>
                </Col>
            </Row>
        </Form>
    </>)
}

export default ServiceForm;