import React, { FC, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { ServiceRequest } from '../../../services/garage/list-service-requests.service';

type ContextType = {
    serviceRequests: ServiceRequest[];
    setServiceRequests: React.Dispatch<SetStateAction<any[]>> | null;
    selectedRecordID: React.Key[];
    setSelectedRecordID: React.Dispatch<SetStateAction<React.Key[]>> | null

};

const initialState: ContextType = {
    serviceRequests: [],
    setServiceRequests: null,
    selectedRecordID: [],
    setSelectedRecordID: null

};

const ModelContext = React.createContext<ContextType>(initialState);

interface ModelProviderInteface {
    children: ReactNode;
}

const ModelProvider: FC<ModelProviderInteface> = ({ children }) => {
    const [serviceRequests, setServiceRequests] = useState<any[]>([]);
    const [selectedRecordID, setSelectedRecordID] = useState<React.Key[]>([]);


    return (
        <ModelContext.Provider
            value={{
                serviceRequests,
                setServiceRequests,
                selectedRecordID,
                setSelectedRecordID
            }}
        >
            {children}
        </ModelContext.Provider>
    );
};

const useModel = () => {
    return React.useContext(ModelContext);
};

export { useModel, ModelProvider };
