import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

interface ArgsInterface {
  body: Record<string, any>;
}

const verifyOtp = (args: ArgsInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.VERIFY_OTP;
  return axios.post(url, args.body);
};

export default verifyOtp;
