import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteSellers, listSellers, ListSellersPayload } from '../../services/seller';

export const getSellerList = createAsyncThunk(
  'seller/getSellerList',
  async (payload: ListSellersPayload, { rejectWithValue }) => {
    try {
      const response = await listSellers(payload);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const exportSellerList = createAsyncThunk(
  'seller/exportSellerList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listSellers({
        as: 'csv',
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteSellerList = createAsyncThunk(
  'seller/deleteSellers',
  async (payload: { ids: number[] }, { rejectWithValue }) => {
    try {
      const response = await deleteSellers({
        ...payload,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const sellerActions = {
  getSellerList,
  deleteSellerList,
  exportSellerList,
};
