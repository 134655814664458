import axios from 'axios';
import { Cookies } from 'react-cookie';
import APP_CONSTANTS from '../utils/constants/app.constants';

const requestMiddleware = () => {
  axios.interceptors.request.use((config: any) => {
    const cookies = new Cookies();
    let token = cookies.get(APP_CONSTANTS.LOCAL_STORAGE_KEYS.TOKEN) || null;
    if (token) {
      config['headers']['Authorization'] = 'Bearer ' + token;
    }
    return config;
  });
};

export default requestMiddleware;
