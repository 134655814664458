import { Typography } from "antd"
import { FC } from "react";


interface FormLabelInterface {
    children: string;
    level?: 1 | 2 | 3 | 4 | 5;
}
export const FormLabel: FC<FormLabelInterface> = ({ children, level = 5 }) => {
    return (
        <Typography.Title level={5} className="mb-0" style={{ fontWeight: '500' }}>
            {children}
        </Typography.Title>
    );
};