import { useEffect } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { setPageTitle } from "../../../store/layout/layoutSlice";
import ServiceRequestForm from "./components/service-request.form";
import { useModel } from "./model";


const ServiceRequestPage = () => {

    const { serviceRequests } = useModel();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setPageTitle('Service Request'));
    }, []);
    return (
        <ServiceRequestForm />
    )
}

export default ServiceRequestPage;