import React, { FC, useEffect, useState } from 'react';
import { Image, Layout, Menu, Space } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import {
  AppstoreOutlined,
  AreaChartOutlined,
  BlockOutlined,
  BuildOutlined,
  DatabaseOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Logo from '../../components/logo.component';
import { useAppSelector } from '../../store/hooks';
import { layoutSelectors } from '../../store/layout/layoutSlice';
import { Link } from 'react-router-dom';
import "./SideBar.less"
import { userSelectors } from '../../store/user/userSlice';
import PAGES_PATHS from '../../utils/constants/pages-path.constants';

interface HeaderProps { }

const { Sider } = Layout;

const SideBar: FC<HeaderProps> = () => {
  const isSidebarOpen = useAppSelector(layoutSelectors.getSidebarOpen);
  const user = useAppSelector(userSelectors.getUser);
  const userRole = useAppSelector(userSelectors.getUserRole);
  const [items, setItems] = useState<ItemType[]>([]);

  const adminRoutes = [
    {
      key: 'dashboard',
      label: <Link to="/dashboard" className='sidebar-nav-link-tag' >Dashboard</Link>,
      icon: <AppstoreOutlined />,
    },
    {
      key: 'catalog',
      label: 'Catalog',
      type: 'group',
      children: [
        {
          key: 'dealer',
          label: 'Dealer / Retailer',
          icon: <UserOutlined />,
        },
        {
          key: 'products',
          label: 'Products',
          icon: <BuildOutlined />,
          // children: [],
        },
        {
          key: 'inventory',
          label: 'Inventory',
          icon: <DatabaseOutlined />,
        },
        {
          key: 'services',
          label: <Link to="/admin/services" className='sidebar-nav-link-tag'>Add Service</Link>,
          icon: <DatabaseOutlined />,
        },
        {
          key: 'adminServiceRequests',
          label: <Link to="/admin/services/request" className='sidebar-nav-link-tag'>Service Requests</Link>,
          icon: <DatabaseOutlined />,
        },
      ],
    },
    {
      key: 'sell',
      label: 'Sell',
      type: 'group',
      children: [
        {
          key: 'orders',
          label: 'Orders',
          icon: <BlockOutlined />,
        },
        {
          key: 'customers',
          label: 'Customers',
          icon: <UsergroupAddOutlined />,
        },
        {
          key: 'sale history',
          label: 'Sale History',
          icon: <AreaChartOutlined />,
        },
      ],
    },
    {
      key: 'reports',
      label: 'Reports',
      type: 'group',
      children: [
        {
          key: 'products report',
          label: 'Products Report',
          icon: <BuildOutlined />,
        },
        {
          key: 'sales report',
          label: 'Sales Report',
          icon: <AreaChartOutlined />,
        },
        {
          key: 'inventory report',
          label: 'Inventory Report',
          icon: <DatabaseOutlined />,
        },
      ],
    },
  ]

  const sellerRoutes = [
    {
      key: 'dashboard',
      label: <Link to="/dashboard" className='sidebar-nav-link-tag' >Dashboard</Link>,
      icon: <AppstoreOutlined />,
    },
    {
      key: 'catalog',
      label: 'Catalog',
      type: 'group',
      children: [
        {
          key: 'dealer',
          label: 'Dealer / Retailer',
          icon: <UserOutlined />,
        },
        {
          key: 'products',
          label: 'Products',
          icon: <BuildOutlined />,
          // children: [],
        },
        {
          key: 'inventory',
          label: 'Inventory',
          icon: <DatabaseOutlined />,
        }
      ],
    },
    {
      key: 'sell',
      label: 'Sell',
      type: 'group',
      children: [
        {
          key: 'orders',
          label: 'Orders',
          icon: <BlockOutlined />,
        },
        {
          key: 'customers',
          label: 'Customers',
          icon: <UsergroupAddOutlined />,
        },
        {
          key: 'sale history',
          label: 'Sale History',
          icon: <AreaChartOutlined />,
        },
      ],
    },
    {
      key: 'reports',
      label: 'Reports',
      type: 'group',
      children: [
        {
          key: 'products report',
          label: 'Products Report',
          icon: <BuildOutlined />,
        },
        {
          key: 'sales report',
          label: 'Sales Report',
          icon: <AreaChartOutlined />,
        },
        {
          key: 'inventory report',
          label: 'Inventory Report',
          icon: <DatabaseOutlined />,
        },
      ],
    },
  ]

  const garageRoutes = [
    {
      key: 'dashboard',
      label: <Link to="/dashboard" className='sidebar-nav-link-tag' >Dashboard</Link>,
      icon: <AppstoreOutlined />,
    },
    {
      key: 'catalog',
      label: 'Catalog',
      type: 'group',
      children: [
        {
          key: 'dealer',
          label: 'Dealer / Retailer',
          icon: <UserOutlined />,
        },
        {
          key: 'products',
          label: 'Products',
          icon: <BuildOutlined />,
          // children: [],
        },
        {
          key: 'inventory',
          label: 'Inventory',
          icon: <DatabaseOutlined />,
        },
        {
          key: 'services',
          label: <Link to="/garage/services" className='sidebar-nav-link-tag'>Add Service</Link>,
          icon: <DatabaseOutlined />,
        },
        {
          key: 'request-services',
          label: <Link to="/garage/services/request" className='sidebar-nav-link-tag'>Request Service</Link>,
          icon: <DatabaseOutlined />,
        },
      ],
    },
    {
      key: 'sell',
      label: 'Sell',
      type: 'group',
      children: [
        {
          key: 'orders',
          label: 'Orders',
          icon: <BlockOutlined />,
        },
        {
          key: 'customers',
          label: 'Customers',
          icon: <UsergroupAddOutlined />,
        },
        {
          key: 'sale history',
          label: 'Sale History',
          icon: <AreaChartOutlined />,
        },
      ],
    },
    {
      key: 'reports',
      label: 'Reports',
      type: 'group',
      children: [
        {
          key: 'products report',
          label: 'Products Report',
          icon: <BuildOutlined />,
        },
        {
          key: 'sales report',
          label: 'Sales Report',
          icon: <AreaChartOutlined />,
        },
        {
          key: 'inventory report',
          label: 'Inventory Report',
          icon: <DatabaseOutlined />,
        },
      ],
    },
  ]

  useEffect(() => {
    if (user?.isAdmin) {
      setItems(adminRoutes);
    } else if (userRole?.id === 5) {
      setItems(garageRoutes);
    } else {
      setItems(sellerRoutes);
    }
  }, [userRole]);
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={!isSidebarOpen}
      collapsedWidth="0"
      className="otomo-sidebar"
    >
      <div>
        <Space className="d-flex justify-content-center">
          <Logo wrapperStyle={{ width: '150px', height: '64px' }} />
        </Space>
      </div>
      <Menu items={items} />
    </Sider>
  );
};

export default SideBar;
