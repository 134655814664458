import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

export interface ListServiceRequestsApi {
  query: {
    business?: number;
  };
}

export interface ServiceRequest {
  id: string;
  vehicleType: string;
  make: string;
  model: string;
  variant: string;
  createdAt: string;
  updatedAt: string;
}

const listServiceRequests = (args?: ListServiceRequestsApi) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE_REQUEST;

  let query: ListServiceRequestsApi['query'] = {};

  if (args?.query) {
    query = args.query;
  }

  return axios.get(url, {
    params: query,
  });
};

export default listServiceRequests;
