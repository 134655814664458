import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import {FC, useEffect, useState} from "react";
import {useModel} from "../model";
import {Time} from "../../../../utils/helpers/getValue";
import {ServiceType} from "../../../../services/service";

type Value = number;
type Values = Value[];

interface ServicesInGarageTableInterface {
  // value?: Values;
  // onChange?: (value: Values) => void;
  list: any[];
}

const ServicesInGarageTable: FC<ServicesInGarageTableInterface> = ({list}) => {

  const [dataSource, setDataSource] = useState<ServiceType[]>();
  const {setSelectedRecordID} = useModel();

  const columns: ColumnsType<ServiceType> = [
    {
      title: "Vehicle Type",
      dataIndex: "vehicleType",
    },
    {
      title: "Make",
      dataIndex: "make",
    },
    {
      title: "Model",
      dataIndex: "model",
    },
    {
      title: "Variant",
      dataIndex: "variant",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Gear",
      dataIndex: "gear",
    },
    {
      title: "Year",
      dataIndex: "year",
    },
    {
      title: "Color",
      dataIndex: "color",
    },
    {
      title: "Service",
      dataIndex: "serviceType",
    },
    {
      title: "Sub Service",
      dataIndex: "subServiceType",
    },
    {
      title: "Time",
      dataIndex: "time",
      render: (cellData) => {
        return new Time(cellData).toString()
      }
    },
  ];

  columns.map(column => {
    if (!('render' in column)) {
      column.render = ({name}) => name;
    }
  })

  const handleOnSelect = (selectedRowKeys: React.Key[], selectedRows: ServiceType[]) => {
    setSelectedRecordID?.(selectedRowKeys);
  }

  const rowSelection: {
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[], selectedRows: ServiceType[]) => void
  } = {
    type: 'checkbox',
    onChange: handleOnSelect,
  };

  useEffect(() => {
    setDataSource(list.map(item => item.service));
  }, [list]);

  return (<>
    <Table
      dataSource={dataSource}
      rowSelection={{
        ...rowSelection
      }}
      rowKey="id"
      columns={columns}
      pagination={false}
    />
  </>)
}

export default ServicesInGarageTable;