import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  ColorType,
  GearType,
  MakeType,
  ModelType,
  ServiceType,
  ServiceTypeType,
  SizeType,
  SubServiceTypeType,
  VariantType,
  VehicleTypeType,
  YearType,
} from '../../services/service';
import servicePageActions from './servicePage.actions';

export interface ServicePageState {
  vehicleType: {
    list: VehicleTypeType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  make: {
    list: MakeType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  model: {
    list: ModelType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  variant: {
    list: VariantType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  size: {
    list: SizeType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  gear: {
    list: GearType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  year: {
    list: YearType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  color: {
    list: ColorType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  serviceType: {
    list: ServiceTypeType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  subServiceType: {
    list: SubServiceTypeType[];
    loading: {
      list: boolean;
      create: boolean;
      delete: boolean;
    };
    errors: {
      list: any;
      create: any;
      delete: any;
    };
  };
  service: {
    list: ServiceType[];
    loading: {
      list: boolean;
      create: boolean;
      bulkDelete: boolean;
    };
    errors: {
      list: any;
      create: any;
      bulkDelete: any;
    };
  };
}

const initialState: ServicePageState = {
  vehicleType: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: false,
    },
  },
  make: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: null,
    },
  },
  model: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: null,
    },
  },
  variant: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: null,
    },
  },
  size: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: null,
    },
  },
  gear: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: null,
    },
  },
  year: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: null,
    },
  },
  color: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: null,
    },
  },
  serviceType: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: null,
    },
  },
  subServiceType: {
    list: [],
    loading: {
      list: false,
      create: false,
      delete: false,
    },
    errors: {
      list: null,
      create: null,
      delete: null,
    },
  },
  service: {
    list: [],
    loading: {
      list: false,
      create: false,
      bulkDelete: false,
    },
    errors: {
      list: null,
      create: null,
      bulkDelete: null,
    },
  },
};

const servicePageSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    resetVehicleTypes: (state) => {
      state.vehicleType.list = [];
    },
    resetMakes: (state) => {
      state.make.list = [];
    },
    resetModels: (state) => {
      state.model.list = [];
    },
    resetVariants: (state) => {
      state.variant.list = [];
    },
    resetSizes: (state) => {
      state.size.list = [];
    },
    resetGears: (state) => {
      state.gear.list = [];
    },
    resetYears: (state) => {
      state.year.list = [];
    },
    resetColors: (state) => {
      state.color.list = [];
    },
    resetServiceTypes: (state) => {
      state.serviceType.list = [];
    },
    resetSubServiceTypes: (state) => {
      state.subServiceType.list = [];
    },
    resetServices: (state) => {
      state.service.list = [];
    },
    addVehicle: (state, action) => {
      state.vehicleType.list.push(action.payload);
      state.vehicleType.list = state.vehicleType.list;
    },
    addMake: (state, action) => {
      state.make.list.push(action.payload);
      state.make.list = state.make.list;
    },
    addModel: (state, action) => {
      state.model.list.push(action.payload);
      state.model.list = state.model.list;
    },
    addVariant: (state, action) => {
      state.variant.list.push(action.payload);
      state.variant.list = state.variant.list;
    },
    addSize: (state, action) => {
      state.size.list.push(action.payload);
      state.size.list = state.size.list;
    },
    addGear: (state, action) => {
      state.gear.list.push(action.payload);
      state.gear.list = state.gear.list;
    },
    addYear: (state, action) => {
      state.year.list.push(action.payload);
      state.year.list = state.year.list;
    },
    addColor: (state, action) => {
      state.color.list.push(action.payload);
      state.color.list = state.color.list;
    },
    addServiceType: (state, action) => {
      state.serviceType.list.push(action.payload);
      state.serviceType.list = state.serviceType.list;
    },
    addSubServiceType: (state, action) => {
      state.subServiceType.list.push(action.payload);
      state.subServiceType.list = state.subServiceType.list;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(servicePageActions.vehicleType.list.pending, (state) => {
        state.vehicleType.loading.list = true;
        state.vehicleType.errors.list = initialState.vehicleType.errors.list;
      })
      .addCase(servicePageActions.vehicleType.list.fulfilled, (state, action) => {
        state.vehicleType.loading.list = false;
        state.vehicleType.list = action.payload.data;
      })
      .addCase(servicePageActions.vehicleType.list.rejected, (state, action) => {
        state.vehicleType.loading.list = false;
        state.vehicleType.errors.list = action.payload;
      })
      .addCase(servicePageActions.vehicleType.delete.pending, (state) => {
        state.vehicleType.loading.delete = true;
        state.vehicleType.errors.delete = initialState.vehicleType.errors.delete;
      })
      .addCase(servicePageActions.vehicleType.delete.fulfilled, (state, action) => {
        state.vehicleType.loading.delete = false;
        state.vehicleType.list = state.vehicleType.list.filter((vehicleType) => {
          return vehicleType.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.vehicleType.delete.rejected, (state, action) => {
        state.vehicleType.loading.delete = false;
        state.vehicleType.errors.delete = action.payload;
      })
      .addCase(servicePageActions.vehicleType.create.pending, (state) => {
        state.vehicleType.loading.create = true;
        state.vehicleType.errors.create = initialState.vehicleType.errors.create;
      })
      .addCase(servicePageActions.vehicleType.create.fulfilled, (state, action) => {
        state.vehicleType.loading.create = false;
      })
      .addCase(servicePageActions.vehicleType.create.rejected, (state, action) => {
        state.vehicleType.loading.create = false;
        state.vehicleType.errors.create = action.payload;
      })
      .addCase(servicePageActions.make.list.pending, (state) => {
        state.make.loading.list = true;
        state.make.errors.list = initialState.make.errors.list;
      })
      .addCase(servicePageActions.make.list.fulfilled, (state, action) => {
        state.make.loading.list = false;
        state.make.list = action.payload.data;
      })
      .addCase(servicePageActions.make.list.rejected, (state, action) => {
        state.make.loading.list = false;
        state.make.errors.list = action.payload;
      })
      .addCase(servicePageActions.make.create.pending, (state) => {
        state.make.loading.create = true;
        state.make.errors.create = initialState.make.errors.create;
      })
      .addCase(servicePageActions.make.create.fulfilled, (state, action) => {
        state.make.loading.create = false;
      })
      .addCase(servicePageActions.make.create.rejected, (state, action) => {
        state.make.loading.create = false;
        state.make.errors.create = action.payload;
      })
      .addCase(servicePageActions.make.delete.pending, (state) => {
        state.make.loading.delete = true;
        state.make.errors.delete = initialState.make.errors.delete;
      })
      .addCase(servicePageActions.make.delete.fulfilled, (state, action) => {
        state.make.loading.delete = false;
        state.make.list = state.make.list.filter((make) => {
          return make.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.make.delete.rejected, (state, action) => {
        state.make.loading.delete = false;
        state.make.errors.delete = action.payload;
      })
      .addCase(servicePageActions.model.list.pending, (state) => {
        state.model.loading.list = true;
        state.model.errors.list = initialState.model.errors.list;
      })
      .addCase(servicePageActions.model.list.fulfilled, (state, action) => {
        state.model.loading.list = false;
        state.model.list = action.payload.data;
      })
      .addCase(servicePageActions.model.list.rejected, (state, action) => {
        state.model.loading.list = false;
        state.model.errors.list = action.payload;
      })
      .addCase(servicePageActions.model.create.pending, (state) => {
        state.model.loading.create = true;
        state.model.errors.create = initialState.model.errors.create;
      })
      .addCase(servicePageActions.model.create.fulfilled, (state, action) => {
        state.model.loading.create = false;
      })
      .addCase(servicePageActions.model.create.rejected, (state, action) => {
        state.model.loading.create = false;
        state.model.errors.create = action.payload;
      })
      .addCase(servicePageActions.model.delete.pending, (state) => {
        state.model.loading.delete = true;
        state.model.errors.delete = initialState.model.errors.delete;
      })
      .addCase(servicePageActions.model.delete.fulfilled, (state, action) => {
        state.model.loading.delete = false;
        state.model.list = state.model.list.filter((model) => {
          return model.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.model.delete.rejected, (state, action) => {
        state.model.loading.delete = false;
        state.model.errors.delete = action.payload;
      })
      .addCase(servicePageActions.variant.list.pending, (state) => {
        state.variant.loading.list = true;
        state.variant.errors.list = initialState.variant.errors.list;
      })
      .addCase(servicePageActions.variant.list.fulfilled, (state, action) => {
        state.variant.loading.list = false;
        state.variant.list = action.payload.data;
      })
      .addCase(servicePageActions.variant.list.rejected, (state, action) => {
        state.variant.loading.list = false;
        state.variant.errors.list = action.payload;
      })
      .addCase(servicePageActions.variant.create.pending, (state) => {
        state.variant.loading.create = true;
        state.variant.errors.create = initialState.variant.errors.create;
      })
      .addCase(servicePageActions.variant.create.fulfilled, (state, action) => {
        state.variant.loading.create = false;
      })
      .addCase(servicePageActions.variant.create.rejected, (state, action) => {
        state.variant.loading.create = false;
        state.variant.errors.create = action.payload;
      })
      .addCase(servicePageActions.variant.delete.pending, (state) => {
        state.variant.loading.delete = true;
        state.variant.errors.delete = initialState.variant.errors.delete;
      })
      .addCase(servicePageActions.variant.delete.fulfilled, (state, action) => {
        state.variant.loading.delete = false;
        state.variant.list = state.variant.list.filter((variant) => {
          return variant.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.variant.delete.rejected, (state, action) => {
        state.variant.loading.delete = false;
        state.variant.errors.delete = action.payload;
      })
      .addCase(servicePageActions.size.list.pending, (state) => {
        state.size.loading.list = true;
        state.size.errors.list = initialState.size.errors.list;
      })
      .addCase(servicePageActions.size.list.fulfilled, (state, action) => {
        state.size.loading.list = false;
        state.size.list = action.payload.data;
      })
      .addCase(servicePageActions.size.list.rejected, (state, action) => {
        state.size.loading.list = false;
        state.size.errors.list = action.payload;
      })
      .addCase(servicePageActions.size.create.pending, (state) => {
        state.size.loading.create = true;
        state.size.errors.create = initialState.size.errors.create;
      })
      .addCase(servicePageActions.size.create.fulfilled, (state, action) => {
        state.size.loading.create = false;
      })
      .addCase(servicePageActions.size.create.rejected, (state, action) => {
        state.size.loading.create = false;
        state.size.errors.create = action.payload;
      })
      .addCase(servicePageActions.size.delete.pending, (state) => {
        state.size.loading.delete = true;
        state.size.errors.delete = initialState.size.errors.delete;
      })
      .addCase(servicePageActions.size.delete.fulfilled, (state, action) => {
        state.size.loading.delete = false;
        state.size.list = state.size.list.filter((size) => {
          return size.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.size.delete.rejected, (state, action) => {
        state.size.loading.delete = false;
        state.size.errors.delete = action.payload;
      })
      .addCase(servicePageActions.gear.list.pending, (state) => {
        state.gear.loading.list = true;
        state.gear.errors.list = initialState.gear.errors.list;
      })
      .addCase(servicePageActions.gear.list.fulfilled, (state, action) => {
        state.gear.loading.list = false;
        state.gear.list = action.payload.data;
      })
      .addCase(servicePageActions.gear.list.rejected, (state, action) => {
        state.gear.loading.list = false;
        state.gear.errors.list = action.payload;
      })
      .addCase(servicePageActions.gear.create.pending, (state) => {
        state.gear.loading.create = true;
        state.gear.errors.create = initialState.gear.errors.create;
      })
      .addCase(servicePageActions.gear.create.fulfilled, (state, action) => {
        state.gear.loading.create = false;
      })
      .addCase(servicePageActions.gear.create.rejected, (state, action) => {
        state.gear.loading.create = false;
        state.gear.errors.create = action.payload;
      })
      .addCase(servicePageActions.gear.delete.pending, (state) => {
        state.gear.loading.delete = true;
        state.gear.errors.delete = initialState.gear.errors.delete;
      })
      .addCase(servicePageActions.gear.delete.fulfilled, (state, action) => {
        state.gear.loading.delete = false;
        state.gear.list = state.gear.list.filter((gear) => {
          return gear.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.gear.delete.rejected, (state, action) => {
        state.gear.loading.delete = false;
        state.gear.errors.delete = action.payload;
      })
      .addCase(servicePageActions.year.list.pending, (state) => {
        state.year.loading.list = true;
        state.year.errors.list = initialState.year.errors.list;
      })
      .addCase(servicePageActions.year.list.fulfilled, (state, action) => {
        state.year.loading.list = false;
        state.year.list = action.payload.data;
      })
      .addCase(servicePageActions.year.list.rejected, (state, action) => {
        state.year.loading.list = false;
        state.year.errors.list = action.payload;
      })
      .addCase(servicePageActions.year.create.pending, (state) => {
        state.year.loading.create = true;
        state.year.errors.create = initialState.year.errors.create;
      })
      .addCase(servicePageActions.year.create.fulfilled, (state, action) => {
        state.year.loading.create = false;
      })
      .addCase(servicePageActions.year.create.rejected, (state, action) => {
        state.year.loading.create = false;
        state.year.errors.create = action.payload;
      })
      .addCase(servicePageActions.year.delete.pending, (state) => {
        state.year.loading.delete = true;
        state.year.errors.delete = initialState.year.errors.delete;
      })
      .addCase(servicePageActions.year.delete.fulfilled, (state, action) => {
        state.year.loading.delete = false;
        state.year.list = state.year.list.filter((year) => {
          return year.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.year.delete.rejected, (state, action) => {
        state.year.loading.delete = false;
        state.year.errors.delete = action.payload;
      })
      .addCase(servicePageActions.color.list.pending, (state) => {
        state.color.loading.list = true;
        state.color.errors.list = initialState.color.errors.list;
      })
      .addCase(servicePageActions.color.list.fulfilled, (state, action) => {
        state.color.loading.list = false;
        state.color.list = action.payload.data;
      })
      .addCase(servicePageActions.color.list.rejected, (state, action) => {
        state.color.loading.list = false;
        state.color.errors.list = action.payload;
      })
      .addCase(servicePageActions.color.create.pending, (state) => {
        state.color.loading.create = true;
        state.color.errors.create = initialState.color.errors.create;
      })
      .addCase(servicePageActions.color.create.fulfilled, (state, action) => {
        state.color.loading.create = false;
      })
      .addCase(servicePageActions.color.create.rejected, (state, action) => {
        state.color.loading.create = false;
        state.color.errors.create = action.payload;
      })
      .addCase(servicePageActions.color.delete.pending, (state) => {
        state.color.loading.delete = true;
        state.color.errors.delete = initialState.color.errors.delete;
      })
      .addCase(servicePageActions.color.delete.fulfilled, (state, action) => {
        state.color.loading.delete = false;
        state.color.list = state.color.list.filter((color) => {
          return color.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.color.delete.rejected, (state, action) => {
        state.color.loading.delete = false;
        state.color.errors.delete = action.payload;
      })
      .addCase(servicePageActions.serviceType.list.pending, (state) => {
        state.serviceType.loading.list = true;
        state.serviceType.errors.list = initialState.serviceType.errors.list;
      })
      .addCase(servicePageActions.serviceType.list.fulfilled, (state, action) => {
        state.serviceType.loading.list = false;
        state.serviceType.list = action.payload.data;
      })
      .addCase(servicePageActions.serviceType.list.rejected, (state, action) => {
        state.serviceType.loading.list = false;
        state.serviceType.errors.list = action.payload;
      })
      .addCase(servicePageActions.serviceType.create.pending, (state) => {
        state.serviceType.loading.create = true;
        state.serviceType.errors.create = initialState.serviceType.errors.create;
      })
      .addCase(servicePageActions.serviceType.create.fulfilled, (state, action) => {
        state.serviceType.loading.create = false;
      })
      .addCase(servicePageActions.serviceType.create.rejected, (state, action) => {
        state.serviceType.loading.create = false;
        state.serviceType.errors.create = action.payload;
      })
      .addCase(servicePageActions.serviceType.delete.pending, (state) => {
        state.serviceType.loading.delete = true;
        state.serviceType.errors.delete = initialState.serviceType.errors.delete;
      })
      .addCase(servicePageActions.serviceType.delete.fulfilled, (state, action) => {
        state.serviceType.loading.delete = false;
        state.serviceType.list = state.serviceType.list.filter((serviceType) => {
          return serviceType.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.serviceType.delete.rejected, (state, action) => {
        state.serviceType.loading.delete = false;
        state.serviceType.errors.delete = action.payload;
      })
      .addCase(servicePageActions.subServiceType.list.pending, (state) => {
        state.subServiceType.loading.list = true;
        state.subServiceType.errors.list = initialState.subServiceType.errors.list;
      })
      .addCase(servicePageActions.subServiceType.list.fulfilled, (state, action) => {
        state.subServiceType.loading.list = false;
        state.subServiceType.list = action.payload.data;
      })
      .addCase(servicePageActions.subServiceType.list.rejected, (state, action) => {
        state.subServiceType.loading.list = false;
        state.subServiceType.errors.list = action.payload;
      })
      .addCase(servicePageActions.subServiceType.create.pending, (state) => {
        state.subServiceType.loading.create = true;
        state.subServiceType.errors.create = initialState.subServiceType.errors.create;
      })
      .addCase(servicePageActions.subServiceType.create.fulfilled, (state) => {
        state.subServiceType.loading.create = false;
      })
      .addCase(servicePageActions.subServiceType.create.rejected, (state, action) => {
        state.subServiceType.loading.create = false;
        state.subServiceType.errors.create = action.payload;
      })
      .addCase(servicePageActions.subServiceType.delete.pending, (state) => {
        state.subServiceType.loading.delete = true;
        state.subServiceType.errors.delete = initialState.subServiceType.errors.delete;
      })
      .addCase(servicePageActions.subServiceType.delete.fulfilled, (state, action) => {
        state.subServiceType.loading.delete = false;
        state.subServiceType.list = state.subServiceType.list.filter((subServiceType) => {
          return subServiceType.id !== action.payload.data.id;
        });
      })
      .addCase(servicePageActions.subServiceType.delete.rejected, (state, action) => {
        state.subServiceType.loading.delete = false;
        state.subServiceType.errors.delete = action.payload;
      })
      .addCase(servicePageActions.service.list.pending, (state) => {
        state.service.loading.list = true;
        state.service.errors.list = initialState.service.errors.list;
      })
      .addCase(servicePageActions.service.list.fulfilled, (state, action) => {
        state.service.loading.list = false;
        state.service.list = action.payload.data;
      })
      .addCase(servicePageActions.service.list.rejected, (state, action) => {
        state.service.loading.list = false;
        state.service.errors.list = action.payload;
      })
      .addCase(servicePageActions.service.create.pending, (state) => {
        state.service.loading.create = true;
        state.service.errors.create = initialState.service.errors.create;
      })
      .addCase(servicePageActions.service.create.fulfilled, (state) => {
        state.service.loading.create = false;
      })
      .addCase(servicePageActions.service.create.rejected, (state, action) => {
        state.service.loading.create = false;
        state.service.errors.create = action.payload;
      })
      .addCase(servicePageActions.service.bulkDelete.pending, (state) => {
        state.service.loading.bulkDelete = true;
        state.service.errors.bulkDelete = initialState.service.errors.bulkDelete;
      })
      .addCase(servicePageActions.service.bulkDelete.fulfilled, (state, action) => {
        state.service.loading.bulkDelete = false;
      })
      .addCase(servicePageActions.service.bulkDelete.rejected, (state, action) => {
        state.service.loading.bulkDelete = false;
        state.service.errors.bulkDelete = action.payload;
      }),
});

export default servicePageSlice.reducer;

export const {
  resetVehicleTypes,
  resetMakes,
  resetModels,
  resetVariants,
  resetSizes,
  resetGears,
  resetYears,
  resetColors,
  resetServiceTypes,
  resetSubServiceTypes,
  addVehicle,
  addMake,
  addModel,
  addVariant,
  addSize,
  addGear,
  addYear,
  addColor,
  addServiceType,
  addSubServiceType,
} = servicePageSlice.actions;

export const servicePageSelector = {
  vehicleType: {
    list: (state: RootState) => state.adminServicePage.vehicleType.list,
    loading: (state: RootState) => state.adminServicePage.vehicleType.loading,
    error: (state: RootState) => state.adminServicePage.vehicleType.errors,
  },
  make: {
    list: (state: RootState) => state.adminServicePage.make.list,
    loading: (state: RootState) => state.adminServicePage.make.loading,
    error: (state: RootState) => state.adminServicePage.make.errors,
  },
  model: {
    list: (state: RootState) => state.adminServicePage.model.list,
    loading: (state: RootState) => state.adminServicePage.model.loading,
    error: (state: RootState) => state.adminServicePage.model.errors,
  },
  variant: {
    list: (state: RootState) => state.adminServicePage.variant.list,
    loading: (state: RootState) => state.adminServicePage.variant.loading,
    error: (state: RootState) => state.adminServicePage.variant.errors,
  },
  size: {
    list: (state: RootState) => state.adminServicePage.size.list,
    loading: (state: RootState) => state.adminServicePage.size.loading,
    error: (state: RootState) => state.adminServicePage.size.errors,
  },
  gear: {
    list: (state: RootState) => state.adminServicePage.gear.list,
    loading: (state: RootState) => state.adminServicePage.gear.loading,
    error: (state: RootState) => state.adminServicePage.gear.errors,
  },
  year: {
    list: (state: RootState) => state.adminServicePage.year.list,
    loading: (state: RootState) => state.adminServicePage.year.loading,
    error: (state: RootState) => state.adminServicePage.year.errors,
  },
  color: {
    list: (state: RootState) => state.adminServicePage.color.list,
    loading: (state: RootState) => state.adminServicePage.color.loading,
    error: (state: RootState) => state.adminServicePage.color.errors,
  },
  serviceType: {
    list: (state: RootState) => state.adminServicePage.serviceType.list,
    loading: (state: RootState) => state.adminServicePage.serviceType.loading,
    error: (state: RootState) => state.adminServicePage.serviceType.errors,
  },
  subServiceType: {
    list: (state: RootState) => state.adminServicePage.subServiceType.list,
    loading: (state: RootState) => state.adminServicePage.subServiceType.loading,
    error: (state: RootState) => state.adminServicePage.subServiceType.errors,
  },
  service: {
    list: (state: RootState) => state.adminServicePage.service.list,
    loading: (state: RootState) => state.adminServicePage.service.loading,
    error: (state: RootState) => state.adminServicePage.service.errors,
  },
};
