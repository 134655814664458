import { Col, message, Row, Typography } from "antd";
import { AxiosResponse } from "axios";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteServiceInGarage, listServiceInGarage, listVehicleTypes } from "../../../services/service";
import { useAppSelector } from "../../../store/hooks";
import { setPageTitle } from "../../../store/layout/layoutSlice";
import { userSelectors } from "../../../store/user/userSlice";
import ServicesInGarageTable from "./components/services-in-garage.table";
import { useModel } from "./model";
import AntdButtonColor from "antd-button-color";
import ServiceForm from "./components/Service.form";

interface GarageProps {
}
const Garage: FC<GarageProps> = () => {
    const {
        vehicleTypes, setVehicleTypes,
        makes, setMakes,
        models, setModels,
        variants, setVariants,
        servicesInGarage, setServicesInGarage,
        selectedRecordID, setSelectedRecordID
    } = useModel();


    const business = useAppSelector(userSelectors.getBusiness);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle('Add Services'));
    }, []);

    const init = async () => {
        try {
            let vehicleTypesRes: AxiosResponse<any, any> = await listVehicleTypes();
            setVehicleTypes?.(vehicleTypesRes.data.data);
        } catch (error) {
            console.log(error);
        }

        if (business !== null) {
            try {
                let servicesInGarageRes: AxiosResponse<any, any> = await listServiceInGarage({
                    garageId: business.id
                });
                setServicesInGarage?.(servicesInGarageRes.data.data);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        init();
    }, []);

    const handleRemoveServices = async () => {
        if (business === null) {
            return;
        }

        try {
            await deleteServiceInGarage({
                ids: selectedRecordID as number[],
                garageId: business.id
            });
            message.success("Services are deleted successfully");
            let servicesInGarage = await listServiceInGarage({
                garageId: business.id
            })

            setServicesInGarage?.(servicesInGarage.data.data);
        } catch (err) {
            console.log(err);
            message.error("Services coudnd't be deleted successfully");
        }
    }

    return (
        <>
            <Row style={{ padding: '2rem' }}>
                <Col span={24} className="mb-3">
                    <ServiceForm />
                </Col>
                <Col span={24}>
                    <div className="d-flex align-items-center justify-content-between">
                        <Typography.Title
                            level={2}
                        >Services</Typography.Title>
                        <div>
                            <AntdButtonColor type="dark"
                                onClick={handleRemoveServices}
                            >
                                Remove
                            </AntdButtonColor>
                        </div>
                    </div>
                    <ServicesInGarageTable
                        list={servicesInGarage}
                    />
                </Col>
            </Row>
        </>
    );
}

export default Garage;