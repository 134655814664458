import { Modal, Result } from "antd";
import { FC } from "react";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";

interface SuccessModal {
    open: boolean;
    onCancel: () => void;
}

const SuccessModal: FC<SuccessModal> = ({ open, onCancel }) => {
    return (
        <Modal
            title={false}
            footer={false}
            open={open}
            onCancel={onCancel}
        >
            <div>
                <Result
                    status="success"
                    title="Successfully registered"
                    subTitle="Invitation has been sent to all members. Your account will be reviewd by Administrator soon."
                    className="large-check"
                />
            </div>
        </Modal>
    )
}

export default SuccessModal;