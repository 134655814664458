import { Avatar, Dropdown, Layout, Menu, Space, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { layoutSelectors, toggleSidebar } from '../store/layout/layoutSlice';
import { FC } from 'react';
import { CgMenuLeftAlt, CgMenuLeft } from 'react-icons/cg';
import { logoutAsync } from '../store/user/userActions';
import PAGES_PATHS from '../utils/constants/pages-path.constants';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

interface BasicHeaderInterface {
  wrapperStyle?: React.CSSProperties;
}

const BasicHeader: FC<BasicHeaderInterface> = ({ wrapperStyle = {} }) => {
  const isSidebarOpen = useAppSelector(layoutSelectors.getSidebarOpen);
  const pageTitle = useAppSelector(layoutSelectors.getPageTitle);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const changeSidebar = (value: boolean) => {
    dispatch(toggleSidebar(value));
  };

  const handleLogout = () => {
    dispatch(logoutAsync()).then(() => {
      navigate(PAGES_PATHS.LOGIN);
    });
  };

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: <Typography.Text onClick={handleLogout}>Logout</Typography.Text>,
        },
      ]}
    />
  );

  return (
    <Header
      style={{
        backgroundColor: 'white',
        border: '1px solid #E5E5E5',
        ...wrapperStyle,
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          {isSidebarOpen ? (
            <CgMenuLeftAlt size="24px" onClick={() => changeSidebar(false)} />
          ) : (
            <CgMenuLeft size="24px" onClick={() => changeSidebar(true)} />
          )}
          {
            <div>
              <Typography.Title level={3} className="mb-0">
                {pageTitle}
              </Typography.Title>
            </div>
          }
        </div>
        {
          <div>
            <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
            </Dropdown>
          </div>
        }
      </div>
    </Header>
  );
};

export default BasicHeader;
