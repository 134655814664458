import React, {FC, Key, useEffect, useState} from 'react';
import {Button, Dropdown, Menu, message, Modal, Space, Table, Tooltip, Typography} from 'antd';
import {HiOutlineAdjustments} from 'react-icons/hi';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {sellerSelectors, updatePaginationDetails} from '../store/seller/SellerSlice';
import {sellerActions} from '../store/seller/SellerActions';
import {ColumnsType} from 'antd/es/table';
import moment from 'moment/moment';
import {constantsSelector} from '../store/constants/constantsSlice';
import constantsActions from '../store/constants/constantsActions';
import {DeleteOutlined, DownOutlined, ExclamationCircleOutlined, EyeOutlined} from '@ant-design/icons';
import SellerExport from '../pages/dashboard/components/sellers.export';
import {useSearchParams} from "react-router-dom";
import businessActions from '../store/business/business.actions';
import {Business} from '../services/business/list-business.service';
import ApprovalStatusTag from "./ApprovalStatusTag";
import ViewBusinessModal from "../pages/dashboard/components/modals/ViewBusinessModal";

interface SellerListProps {
}

const SellerList: FC<SellerListProps> = () => {
  const dispatch = useAppDispatch();
  const sellerList = useAppSelector(sellerSelectors.getSellerList);
  // const storeTypes = useAppSelector(constantsSelector.getStoreTypes);
  const businessRoles = useAppSelector(constantsSelector.listBusinessRoles);
  // const deliveryTypes = useAppSelector(constantsSelector.getDeliveryTypes);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get("currentPage")) || 1;
  const pageSize = Number(searchParams.get("pageSize")) || 10;
  const {totalCount} = useAppSelector(sellerSelectors.getPaginationDetails);

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const columns: ColumnsType<Business> = [
    {
      title: <span className="text-nowrap">ID</span>,
      dataIndex: 'id',
      render: (value) => <Typography>#{value}</Typography>,
    },
    {
      title: <span className="text-nowrap">Role</span>,
      dataIndex: 'businessRole',
      render: (cellData) => {
        return (
          <Typography.Text>
            {
              cellData.name
            }
          </Typography.Text>
        )
      }
    },
    {
      title: <span className="text-nowrap">Status</span>,
      dataIndex: 'approvalStatus',
      render: (cellData) => {
        return (
          <ApprovalStatusTag status={cellData.name}/>
        )
      }
    },
    {
      title: <span className="text-nowrap">Email</span>,
      dataIndex: 'email',
    },
    {
      title: <span className="text-nowrap">Phone Number</span>,
      dataIndex: 'phoneNumber',
    },
    {
      title: <span className="text-nowrap">Business Name</span>,
      dataIndex: 'businessName',
    },
    {
      title: <span className="text-nowrap">Store Address</span>,
      dataIndex: 'storeAddress',
    },
    // {
    //   title: <span className="text-nowrap">Store Type</span>,
    //   dataIndex: 'storeTypeId',
    //   render: (value) => (
    //     <Typography.Text>{businessRoles.find((t) => t.id === value)?.name || '-'}</Typography.Text>
    //   ),
    // },
    {
      title: <span className="text-nowrap">City</span>,
      dataIndex: 'city',
    },
    {
      title: <span className="text-nowrap">Country</span>,
      dataIndex: 'country',
    },
    {
      title: <span className="text-nowrap">ZipCode</span>,
      dataIndex: 'zipCode',
    },
    // {
    //   title: <span className="text-nowrap">Delivery Area</span>,
    //   dataIndex: 'deliveryArea',
    // },
    // {
    //   title: <span className="text-nowrap">Delivery Type</span>,
    //   dataIndex: 'deliveryTypeId',
    //   render: (value) => (
    //     <Typography.Text>{deliveryTypes.find((t) => t.id === value)?.name || '-'}</Typography.Text>
    //   ),
    // },
    {
      title: <span className="text-nowrap">Registered</span>,
      dataIndex: 'createdAt',
      render: (value) => <Typography.Text>{moment(value).format('DD MMM YYYY')}</Typography.Text>,
    },
    {
      title: <span className="text-nowrap">Action</span>,
      dataIndex: 'action',
      render: (_, record) => (
        <Space>
          <ViewBusinessModal business={record}/>
          <Tooltip title="Delete Seller">
            <Button type="primary" icon={<DeleteOutlined/>} onClick={() => handleSellerSingleDelete(record.id)}/>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleSellerSingleDelete = (id: number) => {
    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined/>,
      content: (
        <Typography>
          You are about to delete selected sellers. Are you sure you want to perform this action?
        </Typography>
      ),
      onOk: async () => {
        await dispatch(
          sellerActions.deleteSellerList({
            ids: [Number(id)],
          }),
        ).then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            message.success({
              content: 'Seller delated successfully',
            });
          }
          if (res.meta.requestStatus === 'rejected') {
            message.error({
              content: 'There was problem while deleting seller. Please try again!!',
            });
          }
        });
      },
    });
  };

  const handleSellerMultipleDelete = () => {
    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined/>,
      content: (
        <Typography>
          You are about to delete selected sellers. Are you sure you want to perform this action?
        </Typography>
      ),
      onOk: async () => {
        await dispatch(
          sellerActions.deleteSellerList({
            ids: selectedRowKeys.map((key) => Number(key)),
          }),
        ).then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            message.success({
              content: 'Sellers delated successfully',
            });
          }
          if (res.meta.requestStatus === 'rejected') {
            message.error({
              content: 'There was problem while deleting sellers. Please try again!!',
            });
          }
        });
      },
    });
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    searchParams.set("currentPage", String(page));
    searchParams.set("pageSize", String(pageSize));
    setSearchParams(searchParams);
    dispatch(updatePaginationDetails({
      currentPage: page,
      pageSize: pageSize,
    }));
  }

  const actionMenu = (
    <Menu
      items={[
        {
          key: '1',
          label: <Typography.Text>Delete</Typography.Text>,
          onClick: () => handleSellerMultipleDelete(),
        },
      ]}
    />
  );

  useEffect(() => {
    // if (storeTypes.length === 0) {
    //   dispatch(constantsActions.getStoreTypes());
    // }
    if (businessRoles.length === 0) {
      dispatch(constantsActions.listBusinessRolesAsync());
    }
    // if (deliveryTypes.length === 0) {
    //   dispatch(constantsActions.getDeliveryTypes());
    // }
  }, []);

  useEffect(() => {
    // dispatch(sellerActions.getSellerList({
    //   currentPage: currentPage,
    //   pageSize: pageSize,
    // }));
    dispatch(businessActions.listBusinessAsync({
      queryParams: {
        currentPage: currentPage,
        pageSize: pageSize,
      }
    })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setBusinesses(res.payload.data);
      }
      if (res.meta.requestStatus === 'rejected') {
        message.error({
          content: 'There was problem while deleting seller. Please try again!!',
        });
      }
    })
  }, [searchParams]);

  return (
    <Table<Business>
      rowKey="id"
      dataSource={businesses}
      columns={columns}
      scroll={{x: '100%'}}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys) => {
          setSelectedRowKeys(selectedRowKeys);
        },
      }}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        current: currentPage,
        pageSize: pageSize,
        total: totalCount,
        showSizeChanger: true,
        onChange: onPaginationChange
      }}
      title={() => (
        <div className="d-flex flex-column justify-content-center w-100">
          <div className="d-flex align-items-center">
            <Typography.Title level={5} className="flex-grow-1">
              Seller List
            </Typography.Title>
            <Button size="small" icon={<HiOutlineAdjustments/>}/>
          </div>
          <div className="d-flex col-gap-2">
            <div>
              <Dropdown
                overlay={actionMenu}
                disabled={selectedRowKeys.length === 0}
                trigger={['click']}
              >
                <Button>
                  <Space>
                    Actions
                    <DownOutlined/>
                  </Space>
                </Button>
              </Dropdown>
            </div>
            <div>
              <SellerExport/>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default SellerList;