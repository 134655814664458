import { User } from '../user/userSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteSellerList, getSellerList } from './SellerActions';
import { RootState } from '../store';
import { getValueFromQueryParams, setQueryParamsFromValues } from '../../utils/helpers/getValue';

interface SellerState {
  sellerList: User[];
  paginationDetails: {
    currentPage: number;
    pageSize: number;
    totalCount: number;
  };
  loading: {
    getSellerList: boolean;
    deleteSellerList: boolean;
  };
  error: {
    getSellerList: any;
    deleteSellerList: any;
  };
}

const initialState: SellerState = {
  sellerList: [],
  paginationDetails: {
    currentPage: Number(getValueFromQueryParams('currentPage')) || 1,
    pageSize: Number(getValueFromQueryParams('pageSize')) || 10,
    totalCount: Number(getValueFromQueryParams('totalCount')) || 10,
  },
  loading: {
    getSellerList: false,
    deleteSellerList: false,
  },
  error: {
    getSellerList: null,
    deleteSellerList: null,
  },
};

const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    updatePaginationDetails: (
      state,
      action: PayloadAction<Partial<SellerState['paginationDetails']>>,
    ) => {
      state.paginationDetails = {
        ...state.paginationDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSellerList.pending, (state) => {
        state.loading.getSellerList = true;
      })
      .addCase(getSellerList.fulfilled, (state, action) => {
        state.loading.getSellerList = false;
        const { data, current, pageSize, total } = action.payload;
        state.sellerList = data;
        state.paginationDetails = {
          currentPage: current,
          totalCount: total,
          pageSize,
        };
        setQueryParamsFromValues('currentPage', current);
        // setQueryParamsFromValues('totalCount', total);
        setQueryParamsFromValues('pageSize', pageSize);
      })
      .addCase(getSellerList.rejected, (state, action) => {
        state.loading.getSellerList = false;
        state.error.getSellerList = action.payload;
      })
      .addCase(deleteSellerList.pending, (state) => {
        state.loading.deleteSellerList = true;
      })
      .addCase(deleteSellerList.fulfilled, (state, action) => {
        state.loading.deleteSellerList = false;
        const sellers = [...state.sellerList];
        const idsToRemove = action.payload.ids;
        const updatedSellers = sellers.filter((s) => !idsToRemove.includes(s.id));
        state.sellerList = [...updatedSellers];
      })
      .addCase(deleteSellerList.rejected, (state, action) => {
        state.loading.deleteSellerList = false;
        state.error.deleteSellerList = action.payload;
      }),
});

export const sellerSelectors = {
  getSellerList: (state: RootState) => state.seller.sellerList,
  getPaginationDetails: (state: RootState) => state.seller.paginationDetails,
};

export const { updatePaginationDetails } = sellerSlice.actions;

export default sellerSlice.reducer;
