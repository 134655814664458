import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

export interface CreateServiceRequestApi {
  payload: {
    vehicleType: string;
    make: string;
    model: string;
    variant: string;
    gear: string;
    year: string;
    color: string;
    size: string;
    serviceType: string;
    subServiceType: string;
  };
}

export interface ServiceRequest {
  id: string;
  vehicleType: string;
  make: string;
  model: string;
  variant: string;
  createdAt: string;
  updatedAt: string;
}

const createServiceRequest = (args: CreateServiceRequestApi) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE_REQUEST;

  return axios.post<ServiceRequest>(url, args.payload);
};

export default createServiceRequest;
