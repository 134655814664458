export const getValueFromQueryParams = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key) || '';
};

export const setQueryParamsFromValues = (key: string, value: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(key, String(value));
  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
};

export const getQueryParamsFromUrl = (
  args: {
    paramsToRead?: string[];
    url?: string;
  } = {},
) => {
  let params = new URL(args.url || document.location.href).searchParams;
  let readParams: any = {};

  if (args.paramsToRead && args.paramsToRead.length > 0) {
    args.paramsToRead.forEach((param) => {
      let temp = params.get(param);
      if (temp !== null) {
        readParams[param] = temp;
      }
    });
    return readParams;
  }

  params.forEach(function (val, key) {
    readParams[key] = val;
  });
  return readParams;
};

export class Time {
  time: number;
  constructor(time: number) {
    this.time = time;
  }

  toString(): string {
    let days: number = this.time / 60 / 60 / 24;
    if (days > 1) {
      return days + ' days';
    }

    let h: number, m: number;
    h = Math.floor(this.time / 60 / 60);
    m = Math.floor((this.time / 60 / 60 - h) * 60);
    return h + ' hrs ' + m + ' mins';
  }
}
