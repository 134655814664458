const useDownload = () => {
    const downloadToSystem = (blob: Blob, filename: string) => {
        let link = document.createElement('a');
        document.body.appendChild(link);
        let url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = filename
        link.click();
        document.body.removeChild(link);
    };

    const download = (data: any, filename: string, as: string = 'csv') => {
        let blob: Blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

        downloadToSystem(blob, filename);
    };

    return {
        download,
    };
};

export default useDownload;
