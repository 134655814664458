import { Button } from 'antd';
import axios from 'axios';
import { FC } from 'react';
import useDownload from '../../../hooks/download';
import { useAppDispatch } from '../../../store/hooks';
import { exportSellerList } from '../../../store/seller/SellerActions';

const SellerExport: FC = () => {
  const dispatch = useAppDispatch();
  const download = useDownload();

  const handleOnClick = () => {
    dispatch(exportSellerList()).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        download.download(res.payload, 'sellers-list.csv', 'csv');
      }
    });
  };

  return <Button onClick={handleOnClick}>Export</Button>;
};

export default SellerExport;
