import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import './basic.layout.less';
import SideBar from '../pages/dashboard/SideBar';
import BasicHeader from './basic.header';

const { Content } = Layout;

const BasicLayout = () => {
  return (
    <>
      <Layout id={'basic-layout'}>
        <SideBar />
        <Layout>
          <BasicHeader
            wrapperStyle={{
              padding: '0 16px',
            }}
          />
          <Content>
            <div className="main-content-wrapper h-100 overflow-y-auto">
              <Outlet />
            </div>
          </Content>
          {/* <Footer>Footer</Footer> */}
        </Layout>
      </Layout>
    </>
  );
};

export default BasicLayout;
