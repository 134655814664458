import { createAsyncThunk } from '@reduxjs/toolkit';
import listBusinessRoles from '../../services/business/list-business-roles.service';
import listDeliveryTypes from '../../services/delivery/list-delivery-type';
import listStoreTypes from '../../services/store/list-store-types';

export const getStoreTypes = createAsyncThunk(
  'constants/getStoreTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listStoreTypes();
      return response.data;
    } catch (e: any) {
      return rejectWithValue({
        data: e.response.data,
      });
    }
  },
);

export const getDeliveryTypes = createAsyncThunk(
  'constants/getDeliveryTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listDeliveryTypes();
      return response.data;
    } catch (e: any) {
      return rejectWithValue({
        data: e.response.data,
      });
    }
  },
);

export const listBusinessRolesAsync = createAsyncThunk(
  'constants/listBusinessRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listBusinessRoles();
      return response.data;
    } catch (e: any) {
      return rejectWithValue({
        data: e.response.data,
      });
    }
  },
);

const constantsActions = {
  getDeliveryTypes,
  // getUserRoles,
  getStoreTypes,
  listBusinessRolesAsync,
};

export default constantsActions;
