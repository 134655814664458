import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

export interface ListBusinessesByUserApi {
  params: {
    id: number;
  };
  query?: {
    businessRole?: number;
  };
}
const listBusinessesByUser = (args: ListBusinessesByUserApi) => {
  let url: string =
    BACKEND_PATHS.BASE +
    BACKEND_PATHS.BUSINESS_ROLE_BY_USER.replace(':id', args.params.id.toString());

  let query: Record<string, any> = {};

  if (args.query) {
    query = args.query;
  }

  return axios.get(url, {
    params: query,
  });
};

export default listBusinessesByUser;
