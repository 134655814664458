import React, { FC, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { ColorType, GearType, MakeType, ModelType, ServiceType, ServiceTypeType, SizeType, SubServiceTypeType, VariantType, VehicleTypeType, YearType } from '../../../services/service';

type ContextType = {
  vehicleTypes: VehicleTypeType[];
  makes: MakeType[];
  models: ModelType[];
  variants: VariantType[];
  sizes: SizeType[];
  gears: GearType[];
  years: YearType[];
  colors: ColorType[];
  serviceTypes: ServiceTypeType[];
  subServiceTypes: SubServiceTypeType[];
  services: ServiceType[];
  servicesInGarage: any[];
  selectedRecordID: React.Key[];
  setVehicleTypes: React.Dispatch<SetStateAction<VariantType[]>> | null;
  setMakes: React.Dispatch<SetStateAction<MakeType[]>> | null;
  setModels: React.Dispatch<SetStateAction<ModelType[]>> | null;
  setVariants: React.Dispatch<SetStateAction<VariantType[]>> | null;
  setSizes: React.Dispatch<SetStateAction<SizeType[]>> | null;
  setGears: React.Dispatch<SetStateAction<GearType[]>> | null;
  setYears: React.Dispatch<SetStateAction<YearType[]>> | null;
  setColors: React.Dispatch<SetStateAction<ColorType[]>> | null;
  setServiceTypes: React.Dispatch<SetStateAction<ServiceTypeType[]>> | null;
  setSubServiceTypes: React.Dispatch<SetStateAction<SubServiceTypeType[]>> | null;
  setServices: React.Dispatch<SetStateAction<ServiceType[]>> | null;
  setServicesInGarage: React.Dispatch<SetStateAction<any[]>> | null
  setSelectedRecordID: React.Dispatch<SetStateAction<React.Key[]>> | null
};

const initialState: ContextType = {
  vehicleTypes: [],
  setVehicleTypes: null,
  makes: [],
  setMakes: null,
  models: [],
  setModels: null,
  variants: [],
  setVariants: null,
  sizes: [],
  setSizes: null,
  gears: [],
  setGears: null,
  years: [],
  setYears: null,
  colors: [],
  setColors: null,
  serviceTypes: [],
  setServiceTypes: null,
  subServiceTypes: [],
  setSubServiceTypes: null,
  services: [],
  setServices: null,
  servicesInGarage: [],
  setServicesInGarage: null,
  selectedRecordID: [],
  setSelectedRecordID: null
};

const ModelContext = React.createContext<ContextType>(initialState);

interface ModelProviderInteface {
  children: ReactNode;
}

const ModelProvider: FC<ModelProviderInteface> = ({ children }) => {
  const [vehicleTypes, setVehicleTypes] = useState<VehicleTypeType[]>([]);
  const [models, setModels] = useState<ModelType[]>([]);
  const [makes, setMakes] = useState<MakeType[]>([]);
  const [variants, setVariants] = useState<VariantType[]>([]);
  const [sizes, setSizes] = useState<SizeType[]>([]);
  const [gears, setGears] = useState<GearType[]>([]);
  const [years, setYears] = useState<YearType[]>([]);
  const [colors, setColors] = useState<ColorType[]>([]);
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeType[]>([]);
  const [subServiceTypes, setSubServiceTypes] = useState<SubServiceTypeType[]>([]);
  const [services, setServices] = useState<ServiceType[]>([]);
  const [servicesInGarage, setServicesInGarage] = useState<any[]>([]);
  const [selectedRecordID, setSelectedRecordID] = useState<React.Key[]>([]);


  return (
    <ModelContext.Provider
      value={{
        vehicleTypes,
        setVehicleTypes,
        makes,
        setMakes,
        models,
        setModels,
        variants,
        setVariants,
        sizes,
        setSizes,
        gears,
        setGears,
        years,
        setYears,
        colors,
        setColors,
        serviceTypes,
        setServiceTypes,
        subServiceTypes,
        setSubServiceTypes,
        services,
        setServices,
        servicesInGarage,
        setServicesInGarage,
        selectedRecordID,
        setSelectedRecordID
      }}
    >
      {children}
    </ModelContext.Provider>
  );
};

const useModel = () => {
  return React.useContext(ModelContext);
};

export { useModel, ModelProvider };


