import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddVehicleTypePayload,
  createColor,
  CreateColorPayload,
  createGear,
  CreateGearPayload,
  createMake,
  CreateMakePayload,
  createModel,
  CreateModelPayload,
  createService,
  CreateServicePayload,
  createServiceType,
  CreateServiceTypePayload,
  createSize,
  CreateSizePayload,
  createSubServiceType,
  CreateSubServiceTypePayload,
  createVariant,
  CreateVariantPayload,
  createVehicleType,
  createYear,
  CreateYearPayload,
  deleteColor,
  DeleteColorPayload,
  deleteGear,
  DeleteGearPayload,
  deleteMake,
  DeleteMakePayload,
  deleteModel,
  DeleteModelPayload,
  deleteService,
  DeleteServiceBulkPayload,
  DeleteServicePayload,
  deleteServiceType,
  deleteSize,
  DeleteSizeApi,
  deleteSubServiceType,
  DeleteSubServiceTypePayload,
  deleteVariant,
  deleteVehicleType,
  DeleteVehicleTypePayload,
  deleteYear,
  DeleteYearPayload,
  listColor,
  ListColorInterface,
  ListGearInterface,
  listGears,
  listMakes,
  ListMakesInterface,
  listModels,
  ListModelsInterface,
  listServices,
  listServiceTypes,
  ListServiceTypesInterface,
  listSizes,
  ListSizesInterface,
  listSubServiceTypes,
  ListSubServiceTypesInterface,
  listVariants,
  ListVariantsInterface,
  listVehicleTypes,
  ListVehicleTypesInterface,
  ListYearInterface,
  listYears,
} from '../../services/service';

//vehicle type async
export const listVehicleTypesAsync = createAsyncThunk(
  'service/admin/listVehicleTypes',
  async (payload: ListVehicleTypesInterface, { rejectWithValue }) => {
    try {
      const response = await listVehicleTypes(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createVehicleTypeAsync = createAsyncThunk(
  'service/admin/createVehicleType',
  async (payload: AddVehicleTypePayload, { rejectWithValue }) => {
    try {
      const response = await createVehicleType(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteVehicleTypeAsync = createAsyncThunk(
  'service/admin/deleteVehicleType',
  async (payload: DeleteVehicleTypePayload, { rejectWithValue }) => {
    try {
      const response = await deleteVehicleType(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// make async
export const listMakesAsync = createAsyncThunk(
  'service/admin/listMakes',
  async (payload: ListMakesInterface, { rejectWithValue }) => {
    try {
      const response = await listMakes(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createMakeAsync = createAsyncThunk(
  'service/admin/createMake',
  async (payload: CreateMakePayload, { rejectWithValue }) => {
    try {
      const response = await createMake(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteMakeAsync = createAsyncThunk(
  'service/admin/deleteMake',
  async (payload: DeleteMakePayload, { rejectWithValue }) => {
    try {
      const response = await deleteMake(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// model async
export const listModelsAsync = createAsyncThunk(
  'service/admin/listModels',
  async (payload: ListModelsInterface, { rejectWithValue }) => {
    try {
      const response = await listModels(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createModelAsync = createAsyncThunk(
  'service/admin/createModel',
  async (payload: CreateModelPayload, { rejectWithValue }) => {
    try {
      const response = await createModel(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteModelAsync = createAsyncThunk(
  'service/admin/deleteModel',
  async (payload: DeleteModelPayload, { rejectWithValue }) => {
    try {
      const response = await deleteModel(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// variant async
export const listVariantsAsync = createAsyncThunk(
  'service/admin/listVariants',
  async (payload: ListVariantsInterface, { rejectWithValue }) => {
    try {
      const response = await listVariants(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createVariantAsync = createAsyncThunk(
  'service/admin/createVariant',
  async (payload: CreateVariantPayload, { rejectWithValue }) => {
    try {
      const response = await createVariant(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteVariantAsync = createAsyncThunk(
  'service/admin/deleteVariant',
  async (payload: DeleteMakePayload, { rejectWithValue }) => {
    try {
      const response = await deleteVariant(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// size async
export const listSizeAsync = createAsyncThunk(
  'service/admin/listSizes',
  async (payload: ListSizesInterface, { rejectWithValue }) => {
    try {
      const response = await listSizes(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createSizeAsync = createAsyncThunk(
  'service/admin/createSie',
  async (payload: CreateSizePayload, { rejectWithValue }) => {
    try {
      const response = await createSize(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteSizeAsync = createAsyncThunk(
  'service/admin/deleteSize',
  async (payload: DeleteSizeApi, { rejectWithValue }) => {
    try {
      const response = await deleteSize(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// gear async
export const listGearAsync = createAsyncThunk(
  'service/admin/listGears',
  async (payload: ListGearInterface, { rejectWithValue }) => {
    try {
      const response = await listGears(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createGearAsync = createAsyncThunk(
  'service/admin/createGear',
  async (payload: CreateGearPayload, { rejectWithValue }) => {
    try {
      const response = await createGear(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteGearAsync = createAsyncThunk(
  'service/admin/deleteGear',
  async (payload: DeleteGearPayload, { rejectWithValue }) => {
    try {
      const response = await deleteGear(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// year async
export const listYearAsync = createAsyncThunk(
  'service/admin/listYears',
  async (payload: ListYearInterface, { rejectWithValue }) => {
    try {
      const response = await listYears(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createYearAsync = createAsyncThunk(
  'service/admin/createYear',
  async (payload: CreateYearPayload, { rejectWithValue }) => {
    try {
      const response = await createYear(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteYearAsync = createAsyncThunk(
  'service/admin/deleteYear',
  async (payload: DeleteYearPayload, { rejectWithValue }) => {
    try {
      const response = await deleteYear(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// color async
export const listColorAsync = createAsyncThunk(
  'service/admin/listColors',
  async (payload: ListColorInterface, { rejectWithValue }) => {
    try {
      const response = await listColor(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createColorAsync = createAsyncThunk(
  'service/admin/createColor',
  async (payload: CreateColorPayload, { rejectWithValue }) => {
    try {
      const response = await createColor(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteColorAsync = createAsyncThunk(
  'service/admin/deleteColor',
  async (payload: DeleteColorPayload, { rejectWithValue }) => {
    try {
      const response = await deleteColor(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// service async
export const listServiceTypesAsync = createAsyncThunk(
  'service/admin/listServiceTypes',
  async (payload: ListServiceTypesInterface, { rejectWithValue }) => {
    try {
      const response = await listServiceTypes(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createServiceTypeAsync = createAsyncThunk(
  'service/admin/createServiceType',
  async (payload: CreateServiceTypePayload, { rejectWithValue }) => {
    try {
      const response = await createServiceType(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteServiceTypeAsync = createAsyncThunk(
  'service/admin/deleteServiceType',
  async (payload: DeleteServicePayload, { rejectWithValue }) => {
    try {
      const response = await deleteServiceType(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// sub service async
export const listSubServiceTypesAsync = createAsyncThunk(
  'service/admin/listSubServices',
  async (payload: ListSubServiceTypesInterface, { rejectWithValue }) => {
    try {
      const response = await listSubServiceTypes(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createSubServiceTypeAsync = createAsyncThunk(
  'service/admin/createSubService',
  async (payload: CreateSubServiceTypePayload, { rejectWithValue }) => {
    try {
      const response = await createSubServiceType(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteSubServiceTypeAsync = createAsyncThunk(
  'service/admin/deleteSubService',
  async (payload: DeleteSubServiceTypePayload, { rejectWithValue }) => {
    try {
      const response = await deleteSubServiceType(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

// service async
export const listServicesAsync = createAsyncThunk(
  'service/admin/listServices',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listServices();
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createServiceAsync = createAsyncThunk(
  'service/admin/createService',
  async (payload: CreateServicePayload, { rejectWithValue }) => {
    try {
      const response = await createService(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const deleteBulkServiceAsync = createAsyncThunk(
  'service/admin/deleteBulkServices',
  async (payload: DeleteServiceBulkPayload, { rejectWithValue }) => {
    try {
      const response = await deleteService(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

const servicePageActions = {
  vehicleType: {
    list: listVehicleTypesAsync,
    create: createVehicleTypeAsync,
    delete: deleteVehicleTypeAsync,
  },
  make: {
    list: listMakesAsync,
    create: createMakeAsync,
    delete: deleteMakeAsync,
  },
  model: {
    list: listModelsAsync,
    create: createModelAsync,
    delete: deleteModelAsync,
  },
  variant: {
    list: listVariantsAsync,
    create: createVariantAsync,
    delete: deleteVariantAsync,
  },
  size: {
    list: listSizeAsync,
    create: createSizeAsync,
    delete: deleteSizeAsync,
  },
  gear: {
    list: listGearAsync,
    create: createGearAsync,
    delete: deleteGearAsync,
  },
  year: {
    list: listYearAsync,
    create: createYearAsync,
    delete: deleteYearAsync,
  },
  color: {
    list: listColorAsync,
    create: createColorAsync,
    delete: deleteColorAsync,
  },
  serviceType: {
    list: listServiceTypesAsync,
    create: createServiceTypeAsync,
    delete: deleteServiceTypeAsync,
  },
  subServiceType: {
    list: listSubServiceTypesAsync,
    create: createSubServiceTypeAsync,
    delete: deleteSubServiceTypeAsync,
  },
  service: {
    list: listServicesAsync,
    create: createServiceAsync,
    bulkDelete: deleteBulkServiceAsync,
  },
};

export default servicePageActions;
