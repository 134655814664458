interface AppConstantsInterface {
  USER_TYPES: Record<'ADMIN', { NAME: string; ID: number }>;
  LOCAL_STORAGE_KEYS: Record<'TOKEN' | 'USER' | 'USER_ROLE' | 'USER_ROLES' | 'BUSINESS', string>;
  BUSINESS_ROLE_USER_ROLE: Record<number, number>;
  APPROVAL_STATUS_COLOR_MAPPING: Record<string, string>;
  ERROR: {
    MESSAGE: {
      DEFAULT: string;
    };
  };
}

const APP_CONSTANTS: AppConstantsInterface = {
  LOCAL_STORAGE_KEYS: {
    TOKEN: 'token',
    USER: 'user',
    USER_ROLE: 'user_role',
    USER_ROLES: 'user_roles',
    BUSINESS: 'business',
  },
  USER_TYPES: {
    ADMIN: {
      NAME: 'Admin',
      ID: 1,
    },
  },
  BUSINESS_ROLE_USER_ROLE: {
    1: 2,
    2: 3,
    3: 4,
    4: 5,
  },
  APPROVAL_STATUS_COLOR_MAPPING: {
    approved: '#52c41a',
    pending: '#1677ff',
    disapproved: '#ff4d4f',
    waiting_for_approval: 'rgba(0,0,0,.88)',
  },
  ERROR: {
    MESSAGE: {
      DEFAULT: 'Something went wrong!',
    },
  },
};

export default APP_CONSTANTS;
