import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Col, Input, List, message, Modal, Row, Space, Typography } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "antd-button-color";
import servicePageActions from "../../../../../store/admin/servicePage.actions";
import { addSubServiceType, servicePageSelector } from "../../../../../store/admin/servicePage.slice";
import { SubServiceTypeType } from "../../../../../services/service";

interface SubServiceTypeModalProps {
    onCreate?: (subServiceType: SubServiceTypeType) => void;
}

const CreateSubServiceType: FC<SubServiceTypeModalProps> = ({ onCreate }) => {
    const dispatch = useAppDispatch();

    const subServiceTypeList = useAppSelector(servicePageSelector.subServiceType.list);
    const [open, setOpen] = useState<boolean>(false);
    const { create: createSubServiceTypeLoading } = useAppSelector(servicePageSelector.subServiceType.loading);
    const { create: createSubServiceTypeError } = useAppSelector(servicePageSelector.subServiceType.error);

    const [newSubServiceType, setNewSubServiceType] = useState('');

    const removeSubServiceType = (id: number) => {
        dispatch(servicePageActions.subServiceType.delete({
            id: id
        }))
    }

    const createNewSubServiceType = () => {
        dispatch(servicePageActions.subServiceType.create({
            name: newSubServiceType
        })).then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
                setNewSubServiceType('');
                dispatch(addSubServiceType(res.payload.data));
                onCreate?.(res.payload.data);
            }
        });
    }

    useEffect(() => {
        if (createSubServiceTypeError) {
            message.error(createSubServiceTypeError.data.message);
        }
    }, [createSubServiceTypeError])

    const handleToggleOnClick = () => {
        setOpen(true);
        setNewSubServiceType('');
    }

    const handleOnCancel = () => {
        setOpen(false);
    }

    return (<>
        <Button
            icon={<PlusOutlined />}
            size="small"
            onClick={handleToggleOnClick}
        >
            Add New
        </Button>
        <Modal
            title="Add Sub Service Type"
            open={open}
            footer={null}
            onCancel={handleOnCancel}
        >
            <Row gutter={[0, 20]}>
                <Col span={24}>
                    <List
                        size="small"
                        dataSource={subServiceTypeList}
                        renderItem={item => (
                            <List.Item key={item.id}>
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <Typography>{item.name}</Typography>
                                    <Button
                                        icon={<MinusOutlined />}
                                        size="small"
                                        shape="circle"
                                        type="danger"
                                        onClick={() => removeSubServiceType(item.id)}
                                    />
                                </div>
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Space direction="vertical" className="w-100">
                        <Input
                            placeholder="Add custom type"
                            value={newSubServiceType}
                            onChange={(e) => setNewSubServiceType(e.target.value)}
                        />
                        <Button
                            icon={<PlusOutlined />}
                            onClick={createNewSubServiceType}
                            loading={createSubServiceTypeLoading}
                            disabled={newSubServiceType.length === 0 || createSubServiceTypeLoading}
                        >
                            Add
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Modal>
    </>);
};

export default CreateSubServiceType;