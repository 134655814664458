import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

interface bodyInterface {
  phoneNumber: string;
}
interface ArgsInterface {
  body: bodyInterface;
}

const sendOtp = (args: ArgsInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SEND_OTP;
  return axios.post(url, args.body);
};

export default sendOtp;
