import { createAsyncThunk } from '@reduxjs/toolkit';
import createServiceRequest, {
  CreateServiceRequestApi,
} from '../../services/garage/create-service-request.service';
import listServiceRequests from '../../services/garage/list-service-requests.service';

export const listServiceRequestsAsync = createAsyncThunk(
  'garage/listServiceRequests',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listServiceRequests();
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const createServiceRequestAsync = createAsyncThunk(
  'garage/createServiceRequest',
  async (payload: CreateServiceRequestApi, { rejectWithValue }) => {
    try {
      const response = await createServiceRequest(payload);
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

const makeActions = {
  listMakes: listServiceRequestsAsync,
  createMake: createServiceRequestAsync,
};

export default makeActions;
