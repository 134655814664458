import React, { FC, ReactNode, SetStateAction, useEffect, useState } from 'react';
import listServiceRequests, { ServiceRequest } from '../../../services/garage/list-service-requests.service';

type ContextType = {
  serviceRequests: ServiceRequest[];
  setServiceRequests: React.Dispatch<SetStateAction<any[]>> | null;
};

const initialState: ContextType = {
  serviceRequests: [],
  setServiceRequests: null,
};

const ModelContext = React.createContext<ContextType>(initialState);

interface ModelProviderInteface {
  children: ReactNode;
}

const ModelProvider: FC<ModelProviderInteface> = ({ children }) => {
  const [serviceRequests, setServiceRequests] = useState<any[]>([]);

  return (
    <ModelContext.Provider
      value={{
        serviceRequests,
        setServiceRequests,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
};

const useModel = () => {
  return React.useContext(ModelContext);
};

export { useModel, ModelProvider };
