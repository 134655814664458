import { createSlice } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { Business } from '../../services/business/list-business.service';
import APP_CONSTANTS from '../../utils/constants/app.constants';
import ls from '../../utils/helpers/local-storage';
import { RootState } from '../store';
import {
  getUserRoleAsync,
  listUserRolesAsync,
  loginAsync,
  logoutAsync,
  // registerAsync,
} from './userActions';

export interface UserRole {
  id: number;
  name: string;
  group: string;
  createdAt: string;
  updatedAt: string;
}
export interface User {
  id: number;
  phoneNumber: string;
  isPhoneVerified: boolean;
  email: string;
  firstName: string;
  lastName: string;
  isDelete: boolean;
  isAdmin: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface userState {
  user: User | null;
  isLoggedIn: boolean;
  userRole: UserRole | null;
  userRoles: UserRole[];
  business: Business | null;
  loading: {
    login: boolean;
    register: boolean;
    listUserRoles: boolean;
    getUserRole: boolean;
    getBusiness: boolean;
  };
  error: {
    login: any;
    register: any;
    listUserRoles: any;
    getUserRole: any;
    getBusiness: any;
  };
}
const cookie = new Cookies();

const initialState: userState = {
  user: ls.get(APP_CONSTANTS.LOCAL_STORAGE_KEYS.USER),
  userRole: ls.get(APP_CONSTANTS.LOCAL_STORAGE_KEYS.USER_ROLE),
  isLoggedIn: cookie.get(APP_CONSTANTS.LOCAL_STORAGE_KEYS.TOKEN) ? true : false,
  userRoles: ls.get(APP_CONSTANTS.LOCAL_STORAGE_KEYS.USER_ROLES) || [],
  business: ls.get(APP_CONSTANTS.LOCAL_STORAGE_KEYS.BUSINESS) || [],
  loading: {
    login: false,
    register: false,
    listUserRoles: false,
    getUserRole: false,
    getBusiness: false,
  },
  error: {
    login: null,
    register: null,
    listUserRoles: null,
    getUserRole: null,
    getBusiness: null,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = action.payload;
    },
    setBusiness: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.business = action.payload;
    },
    clearError: (state) => {
      state.error.login = null;
    },
    //   decrement: (state) => {
    //     state.value -= 1;
    //   },
    //   // Use the PayloadAction type to declare the contents of `action.payload`
    //   incrementByAmount: (state, action: PayloadAction<number>) => {
    //     state.value += action.payload;
    //   },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading.login = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading.login = false;
        state.user = action.payload.user;
        state.isLoggedIn = true;
        cookie.set('token', action.payload.token, { path: '/' });
        ls.set(APP_CONSTANTS.LOCAL_STORAGE_KEYS.USER, action.payload.user);
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading.login = false;
        state.error.login = action.payload;
      })
      // .addCase(registerAsync.pending, (state) => {
      //   state.loading.register = true;
      // })
      // .addCase(registerAsync.fulfilled, (state, action) => {
      //   state.loading.register = false;
      //   state.user = action.payload.user;
      //   state.isLoggedIn = true;
      //   cookie.set('token', action.payload.token, { path: '/' });
      //   ls.set(APP_CONSTANTS.LOCAL_STORAGE_KEYS.USER, action.payload.user);
      // })
      // .addCase(registerAsync.rejected, (state, action) => {
      //   state.loading.register = false;
      //   state.error.register = action.payload;
      // })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        const cookie = new Cookies();
        cookie.remove(APP_CONSTANTS.LOCAL_STORAGE_KEYS.TOKEN, { path: '/' });
        ls.clear();
        state = initialState;
      })
      .addCase(listUserRolesAsync.fulfilled, (state, action) => {
        state.loading.listUserRoles = false;
        state.userRoles = action.payload.data;
        ls.set(APP_CONSTANTS.LOCAL_STORAGE_KEYS.USER_ROLES, action.payload.data);
      })
      .addCase(listUserRolesAsync.rejected, (state, action) => {
        state.loading.listUserRoles = false;
        state.error.listUserRoles = action.payload;
      })
      .addCase(getUserRoleAsync.fulfilled, (state, action) => {
        state.loading.getUserRole = false;
        state.userRole = action.payload.data;
        ls.set(APP_CONSTANTS.LOCAL_STORAGE_KEYS.USER_ROLE, action.payload.data);
      })
      .addCase(getUserRoleAsync.pending, (state) => {
        state.loading.getUserRole = true;
      })
      .addCase(getUserRoleAsync.rejected, (state, action) => {
        state.loading.getUserRole = false;
        state.error.getUserRole = action.payload;
      });
  },
});

export const userSelectors = {
  getUser: (state: RootState) => state.user.user,
  getError: (state: RootState) => state.user.error,
  getLoading: (state: RootState) => state.user.loading,
  getUserRole: (state: RootState) => state.user.userRole,
  listUserRoles: (state: RootState) => state.user.userRoles,
  getBusiness: (state: RootState) => state.user.business,
};

export const sessionSelectors = {
  getLoginStatus: (state: RootState) =>
    state.user.isLoggedIn && cookie.get(APP_CONSTANTS.LOCAL_STORAGE_KEYS.TOKEN),
};

export const { setUser, clearError, setBusiness } = userSlice.actions;
export default userSlice.reducer;
