import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Col, Input, List, message, Modal, Row, Space, Typography } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "antd-button-color";
import { addVariant, servicePageSelector } from "../../../../../store/admin/servicePage.slice";
import servicePageActions from "../../../../../store/admin/servicePage.actions";
import { VariantType } from "../../../../../services/service";

interface VariantModalProps {
    onCreate?: (variant: VariantType) => void;
}

const CreateVariant: FC<VariantModalProps> = ({ onCreate }) => {
    const dispatch = useAppDispatch();

    const variantList = useAppSelector(servicePageSelector.variant.list);
    const [open, setOpen] = useState<boolean>(false);
    const { create: createVariantLoading } = useAppSelector(servicePageSelector.variant.loading);
    const { create: createVariantError } = useAppSelector(servicePageSelector.variant.error);

    const [newVariant, setNewVariant] = useState('');

    const removeVariant = (id: number) => {
        dispatch(servicePageActions.variant.delete({
            id: id
        }))
    };

    const createNewVariant = () => {
        dispatch(servicePageActions.variant.create({
            name: newVariant
        })).then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
                setNewVariant('');
                dispatch(addVariant(res.payload.data));
                onCreate?.(res.payload.data);
            }
        });
    }

    useEffect(() => {
        if (createVariantError) {
            message.error(createVariantError.data.message);
        }
    }, [createVariantError])

    const handleToogleOnClick = () => {
        setOpen(true);
        setNewVariant('');
    }

    const handleOnCancel = () => {
        setOpen(false);
    }

    return (<>
        <Button
            icon={<PlusOutlined />}
            size="small"
            onClick={handleToogleOnClick}
        >
            Add New
        </Button>
        <Modal
            title="Add Variant"
            open={open}
            footer={null}
            onCancel={handleOnCancel}
        >
            <Row gutter={[0, 20]}>
                <Col span={24}>
                    <List
                        size="small"
                        dataSource={variantList}
                        renderItem={item => (
                            <List.Item key={item.id}>
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <Typography>{item.name}</Typography>
                                    <Button
                                        icon={<MinusOutlined />}
                                        size="small"
                                        shape="circle"
                                        type="danger"
                                        onClick={() => removeVariant(item.id)}
                                    />
                                </div>
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Space direction="vertical" className="w-100">
                        <Input
                            placeholder="Add custom type"
                            value={newVariant}
                            onChange={(e) => setNewVariant(e.target.value)}
                        />
                        <Button
                            icon={<PlusOutlined />}
                            onClick={createNewVariant}
                            loading={createVariantLoading}
                            disabled={newVariant.length === 0 || createVariantLoading}
                        >
                            Add
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Modal>
    </>);
};

export default CreateVariant;