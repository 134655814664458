import React, {FC} from "react";
import APP_CONSTANTS from "../utils/constants/app.constants";
import {Tag} from "antd";

interface ApprovalStatusTagProps {
  status: keyof typeof APP_CONSTANTS.APPROVAL_STATUS_COLOR_MAPPING
}

const ApprovalStatusTag: FC<ApprovalStatusTagProps> = ({status}) => {
  return (
    <Tag color={APP_CONSTANTS.APPROVAL_STATUS_COLOR_MAPPING[status.replaceAll(' ', '_').toLowerCase()]}>
      {status}
    </Tag>
  );
};

export default ApprovalStatusTag;
