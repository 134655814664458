import { Image } from 'antd';
import { FC, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { sessionSelectors } from '../store/user/userSlice';
import PAGES_PATHS from '../utils/constants/pages-path.constants';

interface AuthInterface { }

const Auth: FC<AuthInterface> = () => {
  const isLoggedIn = useAppSelector(sessionSelectors.getLoginStatus);

  if (!isLoggedIn) {
    return <Navigate to={PAGES_PATHS.LOGIN} replace />;
  }

  return <Outlet />;
};

export default Auth;
