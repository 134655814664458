import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from './counter/counterSlice';
import userReducer from './user/userSlice';
import constantsReducer from './constants/constantsSlice';
import layoutReducer from './layout/layoutSlice';
import sellerReducer from './seller/SellerSlice';
import adminServicePageReducer from './admin/servicePage.slice';

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    counter: counterReducer,
    user: userReducer,
    constants: constantsReducer,
    seller: sellerReducer,
    adminServicePage: adminServicePageReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
