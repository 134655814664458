import { createAsyncThunk } from '@reduxjs/toolkit';
import login from '../../services/auth/login';
import register from '../../services/auth/register';
import listBusinessesByUser, {
  ListBusinessesByUserApi,
} from '../../services/user/list-businesses-by-user-id.service';
import listUserRoles, { ListUserRolesApi } from '../../services/user/list-user-role';

interface loginPayload {
  email: string;
  password: string;
}

export const loginAsync = createAsyncThunk(
  'user/login',
  async (payload: loginPayload, { rejectWithValue }) => {
    try {
      const response = await login({
        body: payload,
      });
      return response.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        data: err.response.data,
      });
    }
    // console.log(response);
    // The value we return becomes the `fulfilled` action payload
  },
);

export interface RegisterPayload {
  formData: FormData;
}

// export const registerAsync = createAsyncThunk(
//   'user/register',
//   async (payload: RegisterPayload, { rejectWithValue }) => {
//     try {
//       const response = await register({
//         body: payload.formData,
//       });
//       return response.data;
//     } catch (err: any) {
//       // Use `err.response.data` as `action.payload` for a `rejected` action,
//       // by explicitly returning it using the `rejectWithValue()` utility
//       return rejectWithValue({
//         data: err.response.data,
//       });
//     }
//   },
// );

export const logoutAsync = createAsyncThunk('user/logout', async (_, { rejectWithValue }) => {
  try {
    return;
  } catch (err: any) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue({
      data: err,
    });
  }
});

export const listUserRolesAsync = createAsyncThunk(
  'user/listUserRoles',
  async (payload: ListUserRolesApi, { rejectWithValue }) => {
    try {
      const response = await listUserRoles(payload);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const getUserRoleAsync = createAsyncThunk(
  'user/getUserRole',
  async (payload: ListUserRolesApi, { rejectWithValue }) => {
    try {
      const response = await listUserRoles(payload);
      if (response.data.data.length > 0) {
        return {
          data: response.data.data[0],
        };
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

export const listBusinessAsync = createAsyncThunk(
  'user/listBusinessesByUser',
  async (payload: ListBusinessesByUserApi, { rejectWithValue }) => {
    try {
      const response = await listBusinessesByUser(payload);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        data: err.response.data,
      });
    }
  },
);

const userActions = {
  loginAsync,
  // registerAsync,
  logoutAsync,
  listUserRolesAsync,
  listBusinessAsync,
  getUserRoleAsync,
};

export default userActions;
