import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

export type VehicleTypeType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type MakeType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ModelType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type VariantType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type SizeType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type GearType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type YearType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ColorType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ServiceTypeType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type SubServiceTypeType = {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ServiceType = {
  id: number;
  vehicleTypeId: number;
  makeId: number;
  modelId: number;
  variantId: number;
  serviceTypeId: number;
  subServiceTypeId: number;
  time: number;
  metroCost: number;
  urbanCost: number;
  ruralCost: number;
  createdAt: string;
  updatedAt: string;
  vehicleType?: VehicleTypeType;
  make?: MakeType;
  model?: ModelType;
  variant?: VariantType;
  size?: SizeType;
  gear?: GearType;
  year?: YearType;
  color?: ColorType;
  serviceType?: ServiceTypeType;
  subServiceType?: SubServiceTypeType;
};

export interface CreateServicePayload {
  vehicleType: number;
  make: number;
  model: number;
  variant: number;
  size: number;
  gear: number;
  year: number;
  color: number;
  serviceType: number;
  subServiceType: number;
  time: string;
  metroCost: number;
  urbanCost: number;
  ruralCost: number;
}

export type ListQueryType = {
  vehicleTypes?: number[];
  makes?: number[];
  models?: number[];
  variants?: number[];
  sizes?: number[];
  gears?: number[];
  years?: number[];
  colors?: number[];
  serviceTypes?: number[];
  subServiceTypes?: number[];
};

interface ListServicesInterface {
  query?: ListQueryType;
}

export const listServices = (args?: ListServicesInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }

    if (args.query.subServiceTypes) {
      query.serviceTypes = args.query.subServiceTypes.toString();
    }
  }

  return axios.get(url, {
    params: query,
  });
};

export const createService = (payload: CreateServicePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE;
  return axios.post(url, payload);
};

export interface DeleteServicePayload {
  id: number;
}
export interface DeleteServiceBulkPayload {
  ids: number[];
}

export const deleteService = (payload: DeleteServicePayload | DeleteServiceBulkPayload) => {
  let url: string = '';
  if ('id' in payload) {
    url = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE + payload.id + '/';
  } else {
    url = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE + '?' + 'ids=' + payload.ids.toString();
  }
  return axios.delete(url);
};

export interface ListVehicleTypesInterface {
  query?: ListQueryType;
}

export const listVehicleTypes = (args: ListVehicleTypesInterface = {}) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.VEHICLE_TYPE;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }

    if (args.query.subServiceTypes) {
      query.serviceTypes = args.query.subServiceTypes.toString();
    }
  }

  return axios.get(url);
};

export interface AddVehicleTypePayload {
  name: string;
}

export const createVehicleType = (payload: AddVehicleTypePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.VEHICLE_TYPE;
  return axios.post(url, payload);
};

export interface DeleteVehicleTypePayload {
  id: number;
}

export const deleteVehicleType = (payload: DeleteVehicleTypePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.VEHICLE_TYPE + payload.id + '/';
  return axios.delete(url);
};
export interface IDPayload {
  id: number;
}

export interface GetMakeListPayload extends Partial<IDPayload> {}

export interface ListMakesInterface {
  query?: ListQueryType;
}

export const listMakes = (args: ListMakesInterface = {}) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.MAKE;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }
  }

  return axios.get(url, {
    params: query,
  });
};

export interface CreateMakePayload {
  name: string;
}

export const createMake = (payload: CreateMakePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.MAKE;
  return axios.post(url, payload);
};

export interface DeleteMakePayload {
  id: number;
}

export const deleteMake = (args: DeleteMakePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.MAKE + args.id + '/';
  return axios.delete(url);
};

export interface ListModelsInterface {
  query?: ListQueryType;
}

export const listModels = (args?: ListModelsInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.MODEL;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }
  }

  return axios.get(url, {
    params: query,
  });
};

export interface CreateModelPayload {
  name: string;
}

export const createModel = (payload: CreateModelPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.MODEL;
  return axios.post(url, payload);
};

export interface DeleteModelPayload {
  id: number;
}

export const deleteModel = (args: DeleteModelPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.MODEL + args.id + '/';
  return axios.delete(url);
};

export interface ListVariantsInterface {
  query?: ListQueryType;
}

export const listVariants = (args?: ListVariantsInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.VARAINT;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }
  }

  return axios.get(url, {
    params: query,
  });
};

export interface CreateVariantPayload {
  name: string;
}

export const createVariant = (payload: CreateVariantPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.VARAINT;
  return axios.post(url, payload);
};

export interface DeleteVariantPayload {
  id: number;
}

export const deleteVariant = (args: DeleteVariantPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.VARAINT + args.id + '/';
  return axios.delete(url);
};

export interface ListSizesInterface {
  query?: ListQueryType;
}

export const listSizes = (args?: ListSizesInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SIZE;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }
  }

  return axios.get(url, {
    params: query,
  });
};

export interface CreateSizePayload {
  name: string;
}

export const createSize = (payload: CreateSizePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SIZE;
  return axios.post(url, payload);
};

export interface DeleteSizeApi {
  id: number;
}

export const deleteSize = (args: DeleteSizeApi) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SIZE + args.id + '/';
  return axios.delete(url);
};

export interface ListGearInterface {
  query?: ListQueryType;
}

export const listGears = (args?: ListGearInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.GEAR;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }
  }

  return axios.get(url, {
    params: query,
  });
};

export interface CreateGearPayload {
  name: string;
}

export const createGear = (payload: CreateGearPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.GEAR;
  return axios.post(url, payload);
};

export interface DeleteGearPayload {
  id: number;
}

export const deleteGear = (args: DeleteGearPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.GEAR + args.id + '/';
  return axios.delete(url);
};

export interface ListYearInterface {
  query?: ListQueryType;
}

export const listYears = (args?: ListYearInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.YEAR;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }
  }

  return axios.get(url, {
    params: query,
  });
};

export interface CreateYearPayload {
  name: string;
}

export const createYear = (payload: CreateYearPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.YEAR;
  return axios.post(url, payload);
};

export interface DeleteYearPayload {
  id: number;
}

export const deleteYear = (args: DeleteYearPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.YEAR + args.id + '/';
  return axios.delete(url);
};

export interface ListColorInterface {
  query?: ListQueryType;
}

export const listColor = (args?: ListColorInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.COLOR;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }
  }

  return axios.get(url, {
    params: query,
  });
};

export interface CreateColorPayload {
  name: string;
}

export const createColor = (payload: CreateColorPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.COLOR;
  return axios.post(url, payload);
};

export interface DeleteColorPayload {
  id: number;
}

export const deleteColor = (args: DeleteColorPayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.COLOR + args.id + '/';
  return axios.delete(url);
};

export interface ListServiceTypesInterface {
  query?: ListQueryType;
}

export const listServiceTypes = (args?: ListServiceTypesInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE_TYPE;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }
  }

  return axios.get(url, {
    params: query,
  });
};

export interface CreateServiceTypePayload {
  name: string;
}

export const createServiceType = (payload: CreateServiceTypePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE_TYPE;
  return axios.post(url, payload);
};

export interface DeleteServicePayload {
  id: number;
}

export const deleteServiceType = (args: DeleteServicePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE_TYPE + args.id + '/';
  return axios.delete(url);
};

export interface ListSubServiceTypesInterface {
  query?: ListQueryType;
}

export const listSubServiceTypes = (args?: ListSubServiceTypesInterface) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SUB_SERVICE_TYPE;

  const query: Record<string, any> = args?.query || {};

  if (args?.query) {
    if (args.query.vehicleTypes) {
      query.vehicleTypes = args.query.vehicleTypes.toString();
    }

    if (args.query.makes) {
      query.makes = args.query.makes.toString();
    }

    if (args.query.models) {
      query.models = args.query.models.toString();
    }

    if (args.query.variants) {
      query.variants = args.query.variants.toString();
    }

    if (args.query.sizes) {
      query.sizes = args.query.sizes.toString();
    }

    if (args.query.gears) {
      query.gears = args.query.gears.toString();
    }

    if (args.query.years) {
      query.years = args.query.years.toString();
    }

    if (args.query.colors) {
      query.colors = args.query.colors.toString();
    }

    if (args.query.serviceTypes) {
      query.serviceTypes = args.query.serviceTypes.toString();
    }
  }
  return axios.get(url, {
    params: query,
  });
};

export interface CreateSubServiceTypePayload {
  name: string;
}

export const createSubServiceType = (payload: CreateSubServiceTypePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SUB_SERVICE_TYPE;
  return axios.post(url, payload);
};

export interface DeleteSubServiceTypePayload {
  id: number;
}

export const deleteSubServiceType = (args: DeleteSubServiceTypePayload) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.SUB_SERVICE_TYPE + args.id + '/';
  return axios.delete(url);
};

export interface CreateServiceInGaragePayload {
  garageId: number;
  payload: {
    service: number | number[];
    isBulk?: boolean;
  };
}

export const createServiceInGarage = (args: CreateServiceInGaragePayload) => {
  if (args.payload.isBulk === undefined) {
    args.payload.isBulk = false;
  }

  let url: string =
    BACKEND_PATHS.BASE +
    BACKEND_PATHS.SERVICE_UNDER_GARAGE.replace(':garageId', args.garageId.toString());
  return axios.post(url, args.payload);
};

interface ListServiceInGaragePayload {
  garageId: number;
}

export const listServiceInGarage = (args: ListServiceInGaragePayload) => {
  let url: string =
    BACKEND_PATHS.BASE +
    BACKEND_PATHS.SERVICE_UNDER_GARAGE.replace(':garageId', args.garageId.toString());
  return axios.get(url);
};

export interface RequestServicePayload {
  vehicleType: string;
  make: string;
  variant: string;
  model: string;
}
export const requestService = (payload: RequestServicePayload) => {
  const url = BACKEND_PATHS.BASE + BACKEND_PATHS.SERVICE + '/request';
  return axios.post(url, payload);
};

export interface DeleteServiceInGaragePayload {
  id: number;
  garageId: number;
}
export interface DeleteServiceInGaragePayloadBulkPayload {
  ids: number[];
  garageId: number;
}

export const deleteServiceInGarage = (
  args: DeleteServiceInGaragePayload | DeleteServiceInGaragePayloadBulkPayload,
) => {
  let url =
    BACKEND_PATHS.BASE +
    BACKEND_PATHS.SERVICE_UNDER_GARAGE.replace(':garageId', args.garageId.toString());

  if ('id' in args) {
    url = url + '/';
  } else {
    url = url + '?' + 'ids=' + args.ids.toString();
  }
  return axios.delete(url);
};
