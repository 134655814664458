import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Space, Typography, Upload } from 'antd';
import userActions, { RegisterPayload } from '../../../store/user/userActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { userSelectors } from '../../../store/user/userSlice';
import constantsActions from '../../../store/constants/constantsActions';
import { constantsSelector } from '../../../store/constants/constantsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { UploadOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import PAGES_PATHS from "../../../utils/constants/pages-path.constants";
import RegistrationForm, { FormDataType } from './components/register.form';
import businessActions from '../../../store/business/business.actions';
import SuccessModal from './components/success.modal';

interface EnterOtpFormProps {
}

const Registration: FC<EnterOtpFormProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { register: registerLoading } = useAppSelector(userSelectors.getLoading);
  const { register: registerError } = useAppSelector(userSelectors.getError);
  const userRoles = useAppSelector(constantsSelector.getSpecificUserRoles('seller'));
  const businessRoles = useAppSelector(constantsSelector.listBusinessRoles);

  const [selectedBusinessRole, setSelectedBusinessRole] = useState<number | undefined>();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const onFinish = async (values: FormDataType) => {
    const formData: any = new FormData();
    let item: keyof FormDataType;
    for (item in values) {
      if (item === 'users') {
        values[item]?.forEach((element, index) => {
          formData.append(`${item}[${index}][firstName]`, element['firstName'])
          formData.append(`${item}[${index}][lastName]`, element['lastName'])
          formData.append(`${item}[${index}][email]`, element['email'])
          formData.append(`${item}[${index}][phoneNumber]`, element['phoneNumber'])
          if (element['dinNo']) {
            formData.append(`${item}[${index}][dinNo]`, element['dinNo']);
          }
          if (element['photo']) {
            formData.append(`${item}[${index}][photo]`, element['photo'])
          }
        });
        continue;
      }

      if (item === 'photos') {
        if (values['photos']) {
          values['photos'].forEach((file) => {
            formData.append('photos', file);
          });
        }
        continue;
      }

      if (item === 'legalDocuments') {
        values['legalDocuments']?.forEach((element, index) => {
          formData.append(`${item}[${index}][type]`, element['type'])
          formData.append(`${item}[${index}][file]`, element['file'])
        });
        continue;
      }

      if (values[item] !== undefined) {
        //@ts-ignore
        formData.append(item, values[item]);
      }
    }

    dispatch(
      businessActions.createBusinessAsync({
        payload: formData,
      }),
    ).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setShowSuccess(true);
        return; //ul
        if (res.payload.user.isAdmin === true) {
          navigate(`/${PAGES_PATHS.DASHBOARD}`);
          return;
        }
        if (res.payload.user.isAdmin === false) {
          navigate(`/${PAGES_PATHS.SERVICE_IN_GARAGE}`);
          return;
        }
        navigate(`/${PAGES_PATHS.THANK_YOU}`);
      }

      if (res.meta.requestStatus === 'rejected') {
        message.error(res.payload.data.message);
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    // dispatch(constantsActions.getUserRoles());
    dispatch(constantsActions.getDeliveryTypes());
    // dispatch(constantsActions.getStoreTypes());
    dispatch(constantsActions.listBusinessRolesAsync());
  }, []);

  useEffect(() => {
    if (registerError) {
      message.error(registerError.data.message || registerError.data.error);
    }
  }, [registerError]);

  return (
    <>
      <Row
        className="h-100 align-items-center"
        justify="center"
        style={{ height: '100vh', overflow: 'auto', paddingBlock: 82 }}
      >
        <Col xs={{ span: 22 }} sm={{ span: 20 }} md={{ span: 18 }}>
          <Space style={{ display: 'flex', justifyContent: 'center' }}>
            <Row
              className="bg-white text-center"
              style={{
                boxShadow: '0px 15px 32px rgba(0, 0, 0, 0.1)',
                borderRadius: 32,
                padding: '42px 41px',
              }}
              gutter={[0, 16]}
              justify="center"
            >
              <Col span={24}>
                <div className="text-center">
                  <Typography.Title level={2} style={{ margin: 0 }}>
                    {selectedBusinessRole ? 'Registration' : 'I would like to register as'}
                  </Typography.Title>
                </div>
              </Col>
              {selectedBusinessRole ? (
                <Col span={24}>
                  <Typography>Signup to discover amazing things near around you</Typography>
                </Col>
              )
                :
                (businessRoles.map((role) => (
                  <Col span={16} key={role.id}>
                    <Button
                      block
                      type={selectedBusinessRole === role.id ? 'primary' : 'default'}
                      size="large"
                      onClick={() => {
                        setSelectedBusinessRole(role.id);
                      }}
                    >
                      {role.name}
                    </Button>
                  </Col>
                ))
                )
              }
              {selectedBusinessRole && (
                <Col span={24}>
                  <RegistrationForm
                    initialValue={{
                      phone: {
                        dialCode: state.dialCode,
                        number: state.phoneNumber
                      },
                      businessRole: selectedBusinessRole
                    }}
                    submitting={registerLoading}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  />
                </Col>
              )}
            </Row>
          </Space>
        </Col>
      </Row>
      <SuccessModal
        open={showSuccess}
        onCancel={() => setShowSuccess(false)}
      />
    </>
  );
};

export default Registration;
