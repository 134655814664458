import axios from 'axios';
import BACKEND_PATHS from '../../utils/constants/backend-paths.constants';

export interface CreateBusinessApi {
  payload: FormData;
}

const createBusiness = (args: CreateBusinessApi) => {
  let url: string = BACKEND_PATHS.BASE + BACKEND_PATHS.BUSINESS;

  return axios.post(url, args.payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default createBusiness;
