import React, { FC, useEffect, useState } from "react";
import {Col, message, Row, Table, Typography} from "antd";
import { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import Button from "antd-button-color";
import { ServiceType } from "../../../../../services/service";
import { servicePageSelector } from "../../../../../store/admin/servicePage.slice";
import servicePageActions from "../../../../../store/admin/servicePage.actions";
import { Time } from "../../../../../utils/helpers/getValue";

interface ServiceListProps {
}

type TableRecord = {
  key: number;

} & ServiceType

const ServiceList: FC<ServiceListProps> = () => {

  const dispatch = useAppDispatch();
  const [dataSource, setDataSource] = useState<TableRecord[]>([]);
  const [selectedRecordID, setSelectedRecordID] = useState<React.Key[]>([]);

  const serviceList = useAppSelector(servicePageSelector.service.list);

  const columns: ColumnsType<TableRecord> = [
    {
      title: "Vehicle Type",
      dataIndex: "vehicleType",
      key: "vehicleType",
      render: (cellData) => cellData.name
    },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
      render: (cellData) => cellData.name

    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (cellData) => cellData.name
    },
    {
      title: "Variant",
      dataIndex: "variant",
      key: "variant",
      render: (cellData) => cellData.name
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (cellData) => cellData.name || '-'
    },
    {
      title: "Gear",
      dataIndex: "gear",
      key: "gear",
      render: (cellData) => cellData.name || '-'
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      render: (cellData) => cellData.name || '-'
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (cellData) => cellData.name || '-'
    },
    {
      title: "Service",
      dataIndex: "serviceType",
      key: "service",
      render: (cellData) => cellData.name
    },
    {
      title: "Sub Service",
      dataIndex: "subServiceType",
      key: "subService",
      render: (cellData) => cellData.name
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (cellData) => {
        return new Time(cellData).toString()
      }
    },
    {
      title: "Metro Price",
      dataIndex: "metroCost",
      key: "metroCost"
    },
    {
      title: "Urban Price",
      dataIndex: "urbanCost",
      key: "urbanCost"
    },
    {
      title: "Rural Price",
      dataIndex: "ruralCost",
      key: "ruralCost"
    },
  ];

  useEffect(() => {
    dispatch(servicePageActions.service.list());
  }, []);

  useEffect(() => {
    let tempDataSource = serviceList.map((service) => ({
      key: service.id,
      ...service
    }));
    setDataSource(tempDataSource);
  }, [serviceList]);


  const handleOnSelect = (selectedRowKeys: React.Key[], selectedRows: TableRecord[]) => {
    setSelectedRecordID(selectedRowKeys);
  }

  const rowSelection: {
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[], selectedRows: TableRecord[]) => void
  } = {
    type: 'checkbox',
    onChange: handleOnSelect,
  };

  const handleRemoveServices = () => {
    dispatch(servicePageActions.service.bulkDelete({
      ids: selectedRecordID as number[]
    })).then(async (res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        await dispatch(servicePageActions.service.list());
      }
      if (res.meta.requestStatus === 'rejected') {
        await message.error({
          content: res.payload.data.message,
        });
      }
    });
  }

  return (
    <Row>
      <Col span={24}>
        <div className="d-flex align-items-center justify-content-between">
          <Typography.Title>Services</Typography.Title>
          <div>
            <Button type="dark"
              onClick={handleRemoveServices}
            >
              Remove
            </Button>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowSelection={{
            ...rowSelection
          }}
          scroll={{
            x: 'auto'
          }}
        />
      </Col>
    </Row>
  );
};

export default ServiceList;