import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Input, InputNumber, message, Row, Select, Space } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import CreateModel from "../modals/CreateModel.model";
import CreateVehicleTypeModal from "../modals/CreateVehicleTypeModal";
import CreateMake from "../modals/CreateMake.model";
import CreateVariant from "../modals/CreateVariant.model";
import CreateServiceType from "../modals/CreateServiceType.model";
import CreateSubServiceType from "../modals/CreateSubServiceType.model";
import { ColorType, CreateServicePayload, GearType, listMakes, MakeType, ModelType, ServiceTypeType, SizeType, SubServiceTypeType, VariantType, VehicleTypeType, YearType } from "../../../../../services/service";
import { FormLabel } from "../../../../../components/forms/form.label";
import TimeInput, { Value } from "../../../../../components/forms/inputs/time.input";
import { resetColors, resetGears, resetMakes, resetModels, resetServiceTypes, resetSizes, resetSubServiceTypes, resetVariants, resetYears, servicePageSelector } from "../../../../../store/admin/servicePage.slice";
import CreateColor from "../modals/CreateColor.model";
import servicePageActions from "../../../../../store/admin/servicePage.actions";
import CreateGear from "../modals/CreateGear.model";
import CreateSize from "../modals/CreateSize.model";
import CreateYear from "../modals/CreateYear.model";
import TimeInputV2 from "../../../../../components/forms/inputs/time.v2.input";

const { Option } = Select;
interface AddServiceFormProps {
}

const AddServiceForm: FC<AddServiceFormProps> = () => {

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [, forceUpdate] = useState({});

  const vehicleTypeList = useAppSelector(servicePageSelector.vehicleType.list);
  const makeList = useAppSelector(servicePageSelector.make.list);
  const modelList = useAppSelector(servicePageSelector.model.list);
  const variantList = useAppSelector(servicePageSelector.variant.list);
  const serviceTypeList = useAppSelector(servicePageSelector.serviceType.list);
  const subServiceTypeList = useAppSelector(servicePageSelector.subServiceType.list);
  const sizeList = useAppSelector(servicePageSelector.size.list);
  const gearList = useAppSelector(servicePageSelector.gear.list);
  const yearList = useAppSelector(servicePageSelector.year.list);
  const colorList = useAppSelector(servicePageSelector.color.list);

  const twoColumnFlex = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    sl: 12,
  };

  const threeColumnFlex = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
    sl: 8,
  };

  const onFinish = async (values: any) => {
    let formattedValues: CreateServicePayload = {
      vehicleType: Number(values['vehicleType']),
      make: Number(values['make']),
      model: Number(values['model']),
      variant: Number(values['variant']),
      size: Number(values['size']),
      gear: Number(values['gear']),
      year: Number(values['year']),
      color: Number(values['color']),
      serviceType: Number(values['serviceType']),
      subServiceType: Number(values['subServiceType']),
      time: values['time'],
      metroCost: values['metroPrice'],
      urbanCost: values['urbanPrice'],
      ruralCost: values['ruralPrice'],
    };

    dispatch(servicePageActions.service.create(formattedValues)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(servicePageActions.service.list());
        message.success('Service is created');
      }

      if (res.meta.requestStatus === 'rejected') {
        dispatch(servicePageActions.service.list());
        message.error(res.payload.data.message);
      }
    });

  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    if (errorInfo.errorFields.length > 0) {
      document.getElementsByClassName(`${errorInfo.errorFields[0]['name'][0]}-FormItem-ErrorFlag`)?.[0]?.scrollIntoView({
        behavior: 'smooth'
      });

      // form.scrollToField(errorInfo.errorFields[0]['name'][0], {
      //   behavior: 'smooth',
      // });
    }
  };

  const validateTime = (_: any, value: number) => {
    console.log('valvalidateTime', value);
    if (!value) {
      return Promise.reject('Time is required');
    }
    return Promise.resolve();
  }

  const handleValuesChange = async (changedValues: any, values: any) => {
    if ('vehicleType' in changedValues) {

      form.setFieldsValue({
        make: undefined,
        model: undefined,
        variant: undefined,
        size: undefined,
        gear: undefined,
        color: undefined,
        year: undefined,
        serviceType: undefined,
        subServiceType: undefined,
      });

      try {
        dispatch(resetMakes());
        dispatch(resetModels());
        dispatch(resetVariants());
        dispatch(resetSizes());
        dispatch(resetGears());
        dispatch(resetYears());
        dispatch(resetColors());
        dispatch(resetServiceTypes());
        dispatch(resetSubServiceTypes());
        dispatch(servicePageActions.make.list({
          query: {
            vehicleTypes: [changedValues.vehicleType]
          }
        }))

      } catch (error) {
        console.log(error);
      }
    }

    if ('make' in changedValues) {
      form.setFieldsValue({
        model: undefined,
        variant: undefined,
        size: undefined,
        gear: undefined,
        color: undefined,
        year: undefined,
        serviceType: undefined,
        subServiceType: undefined,
      });
      try {
        dispatch(resetModels());
        dispatch(resetVariants());
        dispatch(resetSizes());
        dispatch(resetGears());
        dispatch(resetYears());
        dispatch(resetColors());
        dispatch(resetServiceTypes());
        dispatch(resetSubServiceTypes());
        dispatch(servicePageActions.model.list({
          query: {
            vehicleTypes: [values.vehicleType],
            makes: [changedValues.make]
          }
        }))

      } catch (error) {
        console.log(error);
      }
    }

    if ('model' in changedValues) {
      form.setFieldsValue({
        variant: undefined,
        size: undefined,
        gear: undefined,
        color: undefined,
        year: undefined,
        serviceType: undefined,
        subServiceType: undefined,
      });
      try {
        dispatch(resetVariants());
        dispatch(resetSizes());
        dispatch(resetGears());
        dispatch(resetYears());
        dispatch(resetColors());
        dispatch(resetServiceTypes());
        dispatch(resetSubServiceTypes());
        dispatch(servicePageActions.variant.list({
          query: {
            vehicleTypes: [values.vehicleType],
            makes: [values.make],
            models: [changedValues.model]
          }
        }))

      } catch (error) {
        console.log(error);
      }
    }

    if ('variant' in changedValues) {
      form.setFieldsValue({
        size: undefined,
        gear: undefined,
        color: undefined,
        year: undefined,
        serviceType: undefined,
        subServiceType: undefined,
      });
      try {
        dispatch(resetSizes());
        dispatch(resetGears());
        dispatch(resetYears());
        dispatch(resetColors());
        dispatch(resetServiceTypes());
        dispatch(resetSubServiceTypes());
        dispatch(servicePageActions.size.list({
          query: {
            vehicleTypes: [values.vehicleType],
            makes: [values.make],
            models: [values.model],
            variants: [changedValues.variant]
          }
        }))

      } catch (error) {
        console.log(error);
      }
    }

    if ('size' in changedValues) {
      form.setFieldsValue({
        gear: undefined,
        year: undefined,
        color: undefined,
        serviceType: undefined,
        subServiceType: undefined,
      });
      try {
        dispatch(resetGears());
        dispatch(resetYears());
        dispatch(resetColors());
        dispatch(resetServiceTypes());
        dispatch(resetSubServiceTypes());
        dispatch(servicePageActions.gear.list({
          query: {
            vehicleTypes: [values.vehicleType],
            makes: [values.make],
            models: [values.model],
            variants: [values.variant],
            sizes: [changedValues.size],
          }
        }))

      } catch (error) {
        console.log(error);
      }
    }

    if ('gear' in changedValues) {
      form.setFieldsValue({
        year: undefined,
        color: undefined,
        serviceType: undefined,
        subServiceType: undefined,
      });
      try {
        dispatch(resetYears());
        dispatch(resetColors());
        dispatch(resetServiceTypes());
        dispatch(resetSubServiceTypes());
        dispatch(servicePageActions.year.list({
          query: {
            vehicleTypes: [values.vehicleType],
            makes: [values.make],
            models: [values.model],
            variants: [values.variant],
            sizes: [values.size],
            gears: [changedValues.gear]
          }
        }))

      } catch (error) {
        console.log(error);
      }
    }

    if ('year' in changedValues) {
      form.setFieldsValue({
        color: undefined,
        serviceType: undefined,
        subServiceType: undefined,
      });
      try {
        dispatch(resetColors());
        dispatch(resetServiceTypes());
        dispatch(resetSubServiceTypes());
        dispatch(servicePageActions.color.list({
          query: {
            vehicleTypes: [values.vehicleType],
            makes: [values.make],
            models: [values.model],
            variants: [values.variant],
            sizes: [values.size],
            gears: [values.gear],
            years: [changedValues.year]
          }
        }))

      } catch (error) {
        console.log(error);
      }
    }

    if ('color' in changedValues) {
      form.setFieldsValue({
        serviceType: undefined,
        subServiceType: undefined,
      });
      try {
        dispatch(resetServiceTypes());
        dispatch(resetSubServiceTypes());
        dispatch(servicePageActions.serviceType.list({
          query: {
            vehicleTypes: [values.vehicleType],
            makes: [values.make],
            models: [values.model],
            variants: [values.variant],
            sizes: [values.size],
            gears: [values.gear],
            years: [values.year],
            colors: [changedValues.color]
          }
        }))

      } catch (error) {
        console.log(error);
      }
    }

    if ('serviceType' in changedValues) {
      form.setFieldsValue({
        subServiceType: undefined,
      });
      try {
        dispatch(resetSubServiceTypes());
        dispatch(servicePageActions.subServiceType.list({
          query: {
            vehicleTypes: [values.vehicleType],
            makes: [values.make],
            models: [values.model],
            variants: [values.variant],
            sizes: [values.size],
            gears: [values.gear],
            years: [values.year],
            colors: [values.color],
            serviceTypes: [changedValues.serviceType]
          }
        }))

      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleCreateVehicleType = (vehicleType: VehicleTypeType) => {
    const field = 'vehicleType';
    form.setFieldsValue({ [field]: vehicleType.id });
    const changedValues = { [field]: vehicleType.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }

  const handleCreateMake = (make: MakeType) => {
    const field = 'make';
    form.setFieldsValue({ [field]: make.id });
    const changedValues = { [field]: make.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }

  const handleCreateModel = (model: ModelType) => {
    const field = 'model';
    form.setFieldsValue({ [field]: model.id });
    const changedValues = { [field]: model.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }

  const handleCreateVariant = (variant: VariantType) => {
    const field = 'variant';
    form.setFieldsValue({ [field]: variant.id });
    const changedValues = { [field]: variant.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }

  const handleCreateSize = (size: SizeType) => {
    const field = 'size';
    form.setFieldsValue({ [field]: size.id });
    const changedValues = { [field]: size.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }

  const handleCreateGear = (gear: GearType) => {
    const field = 'gear';
    form.setFieldsValue({ [field]: gear.id });
    const changedValues = { [field]: gear.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }

  const handleCreateYear = (year: YearType) => {
    const field = 'year';
    form.setFieldsValue({ [field]: year.id });
    const changedValues = { [field]: year.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }

  const handleCreateColor = (color: ColorType) => {
    const field = 'color';
    form.setFieldsValue({ [field]: color.id });
    const changedValues = { [field]: color.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }

  const handleCreateServiceType = (serviceType: ServiceTypeType) => {
    const field = 'serviceType';
    form.setFieldsValue({ [field]: serviceType.id });
    const changedValues = { [field]: serviceType.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }

  const handleCreateSubServiceType = (subServiceType: SubServiceTypeType) => {
    const field = 'subServiceType';
    form.setFieldsValue({ [field]: subServiceType.id });
    const changedValues = { [field]: subServiceType.id }
    const values = form.getFieldsValue();
    handleValuesChange(changedValues, values)
  }


  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
    dispatch(servicePageActions.vehicleType.list({}))
  }, []);


  return (
    <Form
      form={form}
      size="large"
      name="addService"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={handleValuesChange}
    >
      <Row gutter={[10, 10]}>
        <Col {...twoColumnFlex}>
          <Form.Item
            className="vehicleType-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Vehicle Type</FormLabel>
                <CreateVehicleTypeModal
                  onCreate={handleCreateVehicleType}
                />
              </div>
            }
            colon={false}
            name="vehicleType"
            rules={[
              { required: true, message: "Vehicle Type is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Vehicle Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={vehicleTypeList.map(vehicleType => ({
                value: vehicleType.id,
                label: vehicleType.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...twoColumnFlex}>
          <Form.Item
            className="make-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Add Make</FormLabel>
                <CreateMake
                  onCreate={handleCreateMake}
                />
              </div>
            }
            colon={false}
            name="make"
            rules={[
              { required: true, message: "Make is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Make"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={makeList.map(make => ({
                value: make.id,
                label: make.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...twoColumnFlex}>
          <Form.Item
            className="model-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Add Model</FormLabel>
                <CreateModel
                  onCreate={handleCreateModel}
                />
              </div>
            }
            name="model"
            rules={[
              { required: true, message: "Model is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Model"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={modelList.map(make => ({
                value: make.id,
                label: make.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...twoColumnFlex}>
          <Form.Item
            className="variant-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Add Variant</FormLabel>
                <CreateVariant
                  onCreate={handleCreateVariant}
                />
              </div>
            }
            name="variant"
            rules={[
              { required: true, message: "Variant is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Variant"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={variantList.map(make => ({
                value: make.id,
                label: make.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...twoColumnFlex}>
          <Form.Item
            className="size-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Size</FormLabel>
                <CreateSize
                  onCreate={handleCreateSize}
                />
              </div>
            }
            name="size"
            rules={[
              { required: true, message: "Size is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Size"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={sizeList.map(size => ({
                value: size.id,
                label: size.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...twoColumnFlex}>
          <Form.Item
            className="gear-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Gear</FormLabel>
                <CreateGear
                  onCreate={handleCreateGear}
                />
              </div>
            }
            name="gear"
            rules={[
              { required: true, message: "Gear is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Gear"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={gearList.map(make => ({
                value: make.id,
                label: make.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...twoColumnFlex}>
          <Form.Item
            className="year-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Year</FormLabel>
                <CreateYear
                  onCreate={handleCreateYear}
                />
              </div>
            }
            name="year"
            rules={[
              { required: true, message: "Year is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Year"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={yearList.map(year => ({
                value: year.id,
                label: year.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...twoColumnFlex}>
          <Form.Item
            className="color-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Color</FormLabel>
                <CreateColor
                  onCreate={handleCreateColor}
                />
              </div>
            }
            name="color"
            rules={[
              { required: true, message: "Color is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Color"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={colorList.map(color => ({
                value: color.id,
                label: color.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...twoColumnFlex}>
          <Form.Item
            className="serviceType-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Add Service</FormLabel>
                <CreateServiceType
                  onCreate={handleCreateServiceType}
                />
              </div>
            }
            name="serviceType"
            rules={[
              { required: true, message: "Service is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Service"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={serviceTypeList.map(serviceType => ({
                value: serviceType.id,
                label: serviceType.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...twoColumnFlex}>
          <Form.Item
            className="subServiceType-FormItem-ErrorFlag"
            label={
              <div className="d-flex align-items-center gap-1">
                <FormLabel>Add Sub Service</FormLabel>
                <CreateSubServiceType
                  onCreate={handleCreateSubServiceType}
                />
              </div>
            }
            name="subServiceType"
            rules={[
              { required: true, message: "Sub Service is required" }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Add Sub Service"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={subServiceTypeList.map(subServiceType => ({
                value: subServiceType.id,
                label: subServiceType.name,
              }))}
              allowClear
            />
          </Form.Item>
        </Col>

        {/* <Col span={24}>
          <Form.Item
            className="time-FormItem-ErrorFlag"
            wrapperCol={{ xs: 24, sm: 24, md: 12, xl: 12, xxl: 12, }}

            label={<FormLabel>Time</FormLabel>}
            name="time"
            rules={[
              {
                validator: validateTime
              }
            ]}
          >
            <TimeInput />
          </Form.Item>
        </Col> */}

        <Col span={24}>
          <Form.Item
            className="time-FormItem-ErrorFlag"
            wrapperCol={{ xs: 24, sm: 24, md: 12, xl: 12, xxl: 12, }}

            label={<FormLabel>Time</FormLabel>}
            name="time"
            rules={[
              {
                validator: validateTime
              }
            ]}
          >
            <TimeInputV2 />
          </Form.Item>
        </Col>

        <Col {...threeColumnFlex}>
          <Form.Item
            className="metroPrice-FormItem-ErrorFlag"
            label={<FormLabel>Metro(Price)</FormLabel>}
            name="metroPrice"
            rules={[
              { type: "integer", message: "Please add only integers" },
              { required: true, message: "Metro Price is required" }
            ]}
          >
            <InputNumber style={{ width: "100%" }} controls={false} />
          </Form.Item>
        </Col>

        <Col {...threeColumnFlex}>
          <Form.Item
            className="urbanPrice-FormItem-ErrorFlag"
            label={<FormLabel>Urban(Price)</FormLabel>}
            name="urbanPrice"
            rules={[
              { type: "integer", message: "Please add only integers" },
              { required: true, message: "Urban Price is required" }
            ]}
          >
            <InputNumber style={{ width: "100%" }} controls={false} />
          </Form.Item>
        </Col>

        <Col {...threeColumnFlex}>
          <Form.Item
            className="ruralPrice-FormItem-ErrorFlag"
            label={<FormLabel>Rural / Sami Urban(Price)</FormLabel>}
            name="ruralPrice"
            rules={[
              { type: "integer", message: "Please add only integers" },
              { required: true, message: "Rural Price is required" }
            ]}
          >
            <InputNumber style={{ width: "100%" }} controls={false} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                htmlType="submit"
                type="primary"
                disabled={
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
              >
                Submit Now
              </Button>
            )}
          </Form.Item>
        </Col>

      </Row>
    </Form >
  );
};

export default AddServiceForm;