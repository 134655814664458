import { Image } from 'antd';
import { FC } from 'react';
import logo from '../assets/logo.svg';

interface LogoInterface {
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
}

const Logo: FC<LogoInterface> = ({ wrapperClassName = '', wrapperStyle = {} }) => {
  return <Image src={logo} className={wrapperClassName} style={{ ...wrapperStyle }} />;
};

export default Logo;
